.swapSection {
  text-align: left;

  button {
    background-color: #3c3c3c;
    border: 2px solid #3c3c3c;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 3rem;
    text-transform: uppercase;
    font-weight: bold;

    &.outline {
      background: transparent;
      color: #3c3c3c;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:hover {
      opacity: 0.5;
    }
  }

  .titleImage {
    max-width: 20.8rem;
    width: 100%;
  }

  .warning {
    color: red;
    font-weight: 500;
    font-size: 0.75rem;
  }

  h2.pageTitle {
    font-size: 1.6rem;
    color: #08797f;
    margin-top: 1.5rem;
    margin-bottom: 0rem;
    font-weight: 700;
  }

  .stepTitle {
    font-weight: 500;
    font-size: 1.2rem;
    padding-bottom: 0.5em;
    padding-top: 2rem;
  }

  .stepContent {
    margin-left: 4rem;

    @media screen and (max-width: 768px) {
      margin-left: 0rem;
    }
  }

  .auctionType {
    text-align: left;
    // height: 2rem;
    margin: 0.5rem 0;
    cursor: pointer;

    // radio button
    &::before {
      content: " ";
      display: inline-block;
      height: 1rem;
      width: 1rem;
      margin-right: 0.8rem;
      border-radius: 100%;
      background-color: white;
      border: black 2px solid;
    }

    &.selected::before {
      background-color: black;
    }

    .small {
      font-size: 0.8em;
      margin-left: 1.8rem;
    }
  }

  .tokensTable {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr auto 10rem;

    >div {
      margin-bottom: 1rem;
      margin-right: 0.5rem;
    }

    input {
      width: 100%;
      border: #00000000;
      height: 3em;
      padding: 0 0.5rem;
    }

    .removeButton svg {
      height: 1rem;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr 1fr auto;

      >div {
        margin-bottom: 0rem;
      }

      .warning {
        margin-bottom: 1rem;
        grid-column: span 3;
      }
    }
  }

  .centerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .submitButton {
    text-align: center;
    margin: 2rem auto;
  }
}

.DaoStoreSection {
  .CreatorMenu {
    position: fixed;
    right: 0;
    top: 5rem;
    width: 10rem;
    z-index: 10;

    @media screen and (max-width: 1024px) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-around;
      position: relative;
      top: 0;
      align-items: center;

      .creatorProfileIcon {
        max-width: 3rem;
        margin: 0 1rem;
      }

      // .akaDao{
      //   width: 100%;
      // }
    }

    a.creatorMenuItem {
      display: block;
      color: #3c3c3c;
      text-decoration: none;
      font-size: 0.8rem;
      font-weight: 700;

      &:hover {
        opacity: 1;
      }

      img {
        margin-top: 2rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  .akaDao {
    padding: 1rem;
    //background-color:#f0f0f0;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    font-size: 0.9rem;
    font-weight: 500;

    .label {
      opacity: 0.6;
      background-color: #cfcfcf;
    }

    .text {
      .AkaDaoIcon {
        display: flex;
        align-items: center;
      }

      .refreshBtn {
        cursor: pointer;
      }

      margin-top: 0.2em;

      svg {
        padding: 1px;
        height: 1.2rem;
        width: 1.2rem;
      }
    }
  }

  .notice {
    display: flex;
    align-items: center;
    justify-content: center;

    .noticeIcon {
      width: 5rem;
      margin: 1.5rem;

      img {
        width: 100%;
      }

      h2 {
        font-size: 1.4rem;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .noticeBackground {
      background-color: #B8CA7822;
      border-radius: 2rem;
      padding: 1rem 0;
      font-size: 0.9rem;

      ul {}

      li {
        text-align: left;
        margin: 0.5rem;

        @media screen and (max-width: 480px) {
          font-size: 0.7rem;
        }
      }
    }
  }

  .CreatorSection {
    display: flex;
    flex-wrap: wrap;
    background-color: #f1f1f1;
    margin: 1rem;
    padding: 2rem 0;

    @media screen and (max-width: 1024px) {
      padding: 2rem;
      flex-direction: column;
    }

    .warning {
      color: #FE5454;
      font-size: 0.75rem;
    }

    .note {
      text-align: left;
      margin-top: 3rem;
      //margin-top: auto;
      font-size: 0.75rem;

      @media screen and (max-width: 480px) {
        margin-top: 1.5rem;
      }

      li {
        margin-bottom: 0.5em;
      }

    }

    .left {
      display: flex;
      flex-direction: column;
      padding: 0 1.5rem;
      width: 16rem;
      min-width: 16rem;
      border-right: 1px solid #707070;

      @media screen and (max-width: 1200px) {
        width: 10rem;
        min-width: 10rem;
      }

      @media screen and (max-width: 1024px) {
        padding: 0;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #707070;
      }

      text-align: left;

      .creatorName {
        font-weight: 700;
        font-size: 1rem;
        margin-top: 1rem;
      }

      .subcreatorName {
        font-weight: 700;
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
      }

      .text {
        font-size: 1.2rem;

        .AkaDaoIcon {
          display: flex;
          align-items: center;
        }

        svg {
          height: 1.2em;
        }

        font-weight: 500;
        margin-bottom: 1em;
        font-size: 0.9rem;
      }

      .label {
        font-weight: 700;
        font-size: 0.9rem;
        opacity: 0.5;
      }

      .note_red {
        font-weight: 700;
        font-size: 0.6rem;
        color: #CC0E0E;
        margin-bottom: 1rem;
      }

      .note {
        padding-left: 1rem;
      }
    }

    .right {
      width: calc(100% - 16rem);
      display: flex;
      flex-wrap: wrap;
      padding: 0 0.8rem;
      margin-bottom: -1rem;

      @media screen and (max-width: 1200px) {
        width: calc(100% - 10rem);
        padding: 0 0.5rem;
      }

      @media screen and (max-width: 1024px) {
        width: calc(100% + 2rem);
        padding: 1rem 0;
        margin-left: -1rem;
        margin-bottom: -0.5rem;
      }
    }
  }

  .auctionItem {
    width: 20%;
    padding: 0 1rem;
    padding-bottom: 2rem;
    position: relative;

    @media screen and (max-width: 1400px) {
      width: calc(100% / 3);
      //padding-bottom: 2rem;
    }

    @media screen and (max-width: 1024px) {
      width: 50%;
      //padding-bottom: 2rem;
    }

    .itemName {
      font-size: 0.8rem;
      font-weight: 500;
      line-height: 1 rem;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media screen and (max-width: 480px) {
        font-size: 0.7rem;
      }
    }

    .label {
      font-weight: 500;
      font-size: 0.6rem;
      margin-top: 0.5rem;
      display: inline-block;

      @media screen and (max-width: 1460px) {
        font-size: 0.5rem;
      }

      @media screen and (max-width: 480px) {
        font-size: 0.6rem;
        margin-top: 0.1rem;
      }
    }

    .bidder {
      min-height: 1em;
      font-size: 0.5rem;
      opacity: 0.8;
    }

    .info {
      margin-top: 0.3em;
      font-weight: 500;
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      display: inline-block;

      .AkaDaoIcon {
        display: flex;
        align-items: center;
      }

      svg {
        height: 1.2rem;
      }
    }

    img {
      border: #707070 solid 1px;
      width: 100%;
    }

    .extendTimer {
      position: absolute;
      top: 0rem;
      right: 1rem;
      left: 1rem;
      color: white;
      background-color: #363636;
      z-index: 1;
      font-size: 0.7rem;

      @media screen and (max-width: 480px) {
        font-size: 0.5rem;

      }
    }

    button.button {
      margin-top: 0.6rem;
      font-family: "Zen Kaku Gothic New";
      border-radius: 3rem;
      width: 100%;
      padding: 0.2em 0;
      border: #707070 solid 1px;
      background-color: transparent;
      font-size: 0.8rem;

      @media screen and (max-width: 1400px) {
        border-radius: 2.5rem;

      }

      &:hover {
        opacity: 0.5;
      }
    }

    button:disabled {
      pointer-events: none;
      background-color: #c4c4c4;
      border-color: #c4c4c4;
      color: white;
      opacity: 1;
    }
  }

  .bidModal {
    >div {
      align-items: center;
      height: auto;
    }

    img.thumbnail {
      max-width: 10rem;
    }

    .bidForm {
      text-align: center;
      display: flex;
      margin: 2rem;

      .price {

        //margin-left: 2rem;
        .inputlabel {
          font-size: 0.9rem;
        }

        input {
          background: transparent;
          border: none;
          padding: 0.5rem 0.5rem;
          border-bottom: 1px solid #ffffff22;
          color: white;
          width: 50%;
          text-align: center;
          font-size: 1rem;
        }

        .note {
          margin-top: 0.8rem;
        }
      }
    }

    button {
      width: 12rem;
    }
  }

  .itemModal {
    >div {
      height: auto;
      max-height: 90vh;
    }

    text-align:left;

    .itemRow {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin: 1rem 0;

      @media screen and (max-width: 768px) {
        padding: 1rem;
        padding-top: 2rem;
      }

      .mediaCol {
        margin-bottom: auto;
        width: 40%;

        @media screen and (max-width: 768px) {
          width: 100%;
        }

        img {
          width: 100%;
          display: block;
        }
      }

      .infoCol {
        width: 60%;
        padding-left: 2rem;
        margin-top: auto;
        margin-bottom: 3rem;

        @media screen and (max-width: 768px) {
          width: 100%;
          padding-left: 0;
          margin-top: 1rem;
        }

        h3 {
          margin-top: 0;
        }

        a {
          @media screen and (max-width: 768px) {
            font-size: 0.9em;
          }

          display: inline-block;
          color: #3c3c3c;
          text-decoration: none;
          padding-bottom: 0.2em;
          border-bottom: 1px solid #3c3c3c;
        }
      }
    }
  }

  .collab {
    .creatorProfileIcon {
      margin-right: -2.5rem;

      @media screen and (max-width: 1368px) {
        margin-right: -2rem;
      }
    }
  }

  .creatorProfileIcon {
    border-radius: 400px;
    max-width: 5rem;

    @media screen and (max-width: 1368px) {
      max-width: 4rem;
    }
  }

  .container {
    margin: 0 8rem;

    @media screen and (max-width: 1400x) {
      margin: 0 6rem;
    }

    @media screen and (max-width: 1024px) {
      margin: 0;
    }
  }

  .link {
    text-decoration: none;
    color: black;
  }
}

.break {

  display: flex;
  align-items: center;
  margin: 4rem 0 1rem;

  div {
    width: 60rem;
    font-size: 0.8rem;
    font-weight: 500;
  }

}

.break::before,
.break::after {
  content: "";
  height: 1px;
  width: 100%;
  background-color: #3c3c3c;
}

.showDetailsButton {
  background-color: #3c3c3c;
  color: white;
  border-radius: 2rem;
  padding: 0.5rem 3rem;
  border: none;
  font-family: "ZenKakuGothicNew";
}