.timeline {
  list-style: none;
  padding-inline-start: 0;
  position: relative;
  --decoColor: #08797f;
  --lineWidth: 0.2rem;
  --markerSize: 1.4rem;
  @media (max-width: 768px) {
    margin: 0 1rem;
  }
}

.timeline > li:after {
  display: table;
  content: " ";
  clear: both;
}
// deco line
.timeline > li:before {
  clear: both;
  position: absolute;
  top: 0;
  bottom: 0;
  @media (min-width: 768px) {
    left: calc(50% - (var(--lineWidth) / 2));
  }
  width: var(--lineWidth);
  content: " ";
  background-color: var(--decoColor);
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
.timeline > li {
  position: relative;
  display: block;
}
.timelineMarker {
  display: block;
  position: absolute;
  @media (max-width: 768px) {
    left: calc(0% - (var(--markerSize) / 2));
  }
  left: calc(50% - (var(--markerSize) / 2));
  width: var(--markerSize);
  height: var(--markerSize);
  border-radius: var(--markerSize);
}
.timelinePanel {
  display: block;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    padding-left: 2rem;
  }
  width: 45%;
}
.title {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--textColor);
}
.sub {
  font-weight: bold;
  color: var(--textColor);
  font-size: 1rem;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
}
.right {
  text-align: left;
  .timelinePanel {
    float: right;
  }
}
.left {
  text-align: right;
  .timelinePanel {
    float: left;
  }
  @media screen and (max-width: 768px) {
    text-align: left;
    .timelinePanel {
      float: right;
    }
  }
}
.past {
  opacity: 1;
  .timelineMarker {
    background-color: var(--decoColor);
  }
}
.future {
  // opacity: 0.5;
}
.timelineMarker {
  background-color: var(--backgroundColor);
  border: var(--lineWidth) solid var(--decoColor);
}
.desc {
  color: var(--lightTextColor);
  margin: 0.5rem 0 1.5rem;
  p {
    font-size: 0.9rem;
    margin: 0;
    line-height: 1.5em;
    @media screen and (max-width: 768px) {
      font-size: 0.7rem;
    }
  }
  .hint {
    font-weight: 500;
    color: #fb8000;
  }
}
