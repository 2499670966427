.time {
  font-weight: 500;
  font-size: 1.2em;
}

.refresh {
  margin-left: 0.5rem;
  padding: 0.5rem;
  border: 0.5px white solid;
  border-radius: 0.2rem;
}
.refresh:hover {
  cursor: pointer;
}
