.modalOverlay {
  background-color: #cccccc85;
  position: fixed;
  display: flex;
  top: 4rem;
  bottom: 0;
  left: 0%;
  right: 0;
  z-index: 10;
}

.CloseButton {
  position: absolute;
  top: 1rem;
  right: 1rem;
  height: 2rem;
  width: 2rem;
  cursor: pointer;

  svg {
    height: 100%;
    width: 100%;
  }
}

.ggModal {
  position: relative;
  background-color: #575b59;
  color: white;
  border-radius: 2rem;
  height: 88%;
  max-width: 70%;
  width: 90rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  // padding: 2rem 5%;
  overflow-y: scroll;

  .decoRight,
  .decoLeft {
    z-index: 100;
    position: absolute;
    bottom: 1.25rem;
  }

  .decoLeft {
    background: url("images/deco_left_3.png") no-repeat 50% 50%;
    background-size: contain;
    height: 8rem;
    width: 8rem;
    left: 2rem;

    @media screen and (max-width: 1630px) {
      background-size: contain;
      height: 6rem;
      left: 0.25rem;
    }

    @media screen and (max-width: 1024px) {
      background-size: contain;
      height: 6rem;
      left: 0.25rem;
    }

    @media screen and (max-height: 650px) {
      background: none;
      display: none;
    }

    @media screen and (max-width: 1000px) {
      background: none;
      display: none;
    }
  }

  .decoRight {
    background: url("images/deco_right_3.png") no-repeat 50% 50%;
    background-size: contain;
    height: 8rem;
    width: 12rem;
    right: 0.5rem;

    @media screen and (max-width: 1630px) {
      background-size: contain;
      height: 6rem;
      right: 0.25rem;
    }
    @media screen and (max-width: 1024px) {
      background-size: contain;
      height: 6rem;
      right: 0.25rem;
    }

    @media screen and (max-width: 1000px) {
      background: none;
      display: none;
    }

    @media screen and (max-height: 650px) {
      background: none;
      display: none;
    }

  }

  &::-webkit-scrollbar {
    width: 5px;
    /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0000002a;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
  }

  @media screen and (max-width: 1300px) {
    max-height: 95%;
    max-width: 90%;
    width: 90rem;
    border-radius: 1rem;
    flex-direction: column;
    margin: auto;

    @media screen and (max-width: 768px) {
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  h3.title {
    color: #e1f7f1;
    font-size: 1.8rem;
    margin-top: 3rem;
    margin-bottom: 0.8rem;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 480px) {
      font-size: 1.3rem;
      margin-top: 2.5rem;
    }

    .titledecoLeft {
      background: url("images/title_left.png") no-repeat 50% 50%;
      background-size: 120%;
      height: 3rem;
      width: 3rem;
      margin: 0rem 1rem;

      @media screen and (max-width: 480px) {
        margin: 0rem 0.5rem;
      }
    }

    .titledecoRight {
      background: url("images/title_right.png") no-repeat 50% 50%;
      background-size: 80%;
      height: 3rem;
      width: 3rem;
      margin: 0rem 1rem;

      @media screen and (max-width: 480px) {
        margin: 0rem 0.5rem;
      }
    }
  }

  button {
    font-weight: 400;
    display: block;
    background-color: transparent;
    border-radius: 3rem;
    border: white solid 1px;
    font-family: "Zen Kaku Gothic New", sans-serif;
    color: white;
    padding: 0.4rem 1rem;
    font-size: 0.9rem;
    width: 12rem;
    text-transform: uppercase;

    @media screen and (max-width: 450px) {
      margin-bottom: 2rem;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:hover:enabled {
      background-color: #fff;
      color: #3c3c3c;
    }
  }

  .navButton {
    font-size: 0.8rem;
    position: absolute;
    top: 7rem;
    width: 5rem;
    left: 3rem;

    @media screen and (max-width: 768px) {
      top: 1.5rem;
      left: 1.5rem;
    }
  }

  .tzktLink {
    color: white;
  }
}

/* STEP 1 Welcome */
.row {
  display: flex;
  width: 90%;
  margin: auto;
  max-width: 800px;
}

.welcomeMessage {
  display: flex;
}

.welcomeText {
  margin-top: 0.3rem;
  text-align: left;
  line-height: 1.5em;

  h4.stepTitle {
    font-weight: bold;
    margin-left: 1rem;
  }

  li {
    margin-top: 0.4em;
    font-size: 0.85rem;
    @media screen and (max-width: 480px) {
      font-size: 0.7rem;
    }
  }

  .red {
    color: #f24e4e;
    font-weight: bold;
  }
}

.redeemButton {
  button {
    width: auto;
  }
  margin-bottom: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.teamsCheck {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;

  .hasVoucher,
  .noVoucher {
    height: 2rem;
    margin: 0.75rem;
    position: relative;
    background: url('images/voucher.png');
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    width: 4rem;

    @media screen and (max-width: 1368px) {
      height: 2rem;
      width: 4rem;
      margin: 0.3rem;
    }

    svg {
      position: absolute;
      height: 20px;
      width: 26px;
      top: calc(50% - 10px);
      right: calc(50% - 13px);
    }
  }

  .hasTeam,
  .noTeam {
    margin: 0.3rem;
    position: relative;
    height: 2rem;
    width: 2rem;

    @media screen and (max-width: 1368px) {
      height: 2rem;
      width: 2rem;
      margin: 0.3rem;
    }

    img {
      height: 100%;
      width: 100%;
    }

    svg {
      position: absolute;
      height: 20px;
      width: 26px;
      top: calc(50% - 10px);
      right: calc(50% - 13px);
    }
  }

  .noTeam {
    img {
      filter: grayscale(100%);
    }
  }

  .noVoucher {
    filter: grayscale(100%);
  }
}

/* STEP 2 Select TDTM */
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading {
  height: 150px;
  width: 150px;
  background: url(./images/processing.gif) 50% 50% no-repeat;
  background-size: contain;
  margin-bottom: 3rem;
}

.result {
  .resultIcon {
    height: 80px;
    width: 80px;

    svg {
      height: 100%;
      width: 100%;
    }

    margin-bottom: 1rem;
  }

  .resultMessage {
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 1rem;
  }

  button {
    margin-bottom: 1rem;
  }
}