.modalOverlay {
  background-color: #cccccc85;
  position: fixed;
  display: flex;
  top: 4rem;
  bottom: 0;
  left: 0%;
  right: 0;
  z-index: 10;

  .CloseButton {
    position: absolute;
    top: 2rem;
    right: 2rem;
    height: 2rem;
    width: 2rem;
    cursor: pointer;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  // .decoRight,
  // .decoLeft {
  //   z-index: 100;
  //   position: absolute;
  //   bottom: 6%;
  // }

  // .decoLeft {
  //   background: url("images/deco_left.png") no-repeat 50% 50%;
  //   background-size: contain;
  //   height: 8rem;
  //   width: 10rem;
  //   left: 16%;

  //   @media screen and (max-width: 1300px) {
  //     background-size: contain;
  //     height: 6rem;
  //     left: 5%;
  //   }

  //   @media screen and (max-width: 1024px) {
  //     background-size: contain;
  //     height: 6rem;
  //   }

  //   @media screen and (max-width: 768px) {
  //     background: none;
  //     display: none;
  //   }

  //   @media screen and (min-width: 2400px) {
  //     left: calc(50% - 43rem);
  //   }
  // }

  // .decoRight {
  //   background: url("images/deco_right.png") no-repeat 50% 50%;
  //   background-size: contain;
  //   height: 5rem;
  //   width: 13rem;
  //   right: 18%;

  //   @media screen and (max-width: 1024px) {
  //     background-size: contain;
  //     height: 4rem;
  //   }

  //   @media screen and (max-width: 768px) {
  //     background: none;
  //     display: none;
  //   }

  //   @media screen and (max-width: 1300px) {
  //     background-size: contain;
  //     height: 4rem;
  //     right: 6%;
  //   }

  //   @media screen and (min-width: 2000px) {
  //     right: calc(50% - 43rem);
  //   }
  // }
}

.raffleModal {
  position: relative;
  background-color: #575b59;
  color: white;
  border-radius: 2rem;
  height: 88%;
  max-width: 70%;
  width: 90rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  // padding: 2rem 5%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
    /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0000002a;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
  }

  @media screen and (max-width: 1300px) {
    max-height: 95%;
    max-width: 90%;
    width: 90rem;
    border-radius: 1rem;
    flex-direction: column;
    margin: auto;

    @media screen and (max-width: 768px) {
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  h3.title {
    color: #e1f7f1;
    font-size: 1.8rem;
    margin-top: 3rem;
    margin-bottom: 0.8rem;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 480px) {
      font-size: 1.2rem;
      margin-top: 2.5rem;
    }

    .titledecoLeft {
      background: url("images/title_left.png") no-repeat 50% 50%;
      height: 3rem;
      width: 6rem;
      margin: 0rem 1rem;

      @media screen and (max-width: 480px) {
        background-size: 50%;
        margin: 0rem 0.5rem;
      }
    }

    .titledecoRight {
      background: url("images/title_right.png") no-repeat 50% 50%;
      height: 3rem;
      width: 6rem;
      margin: 0rem 1rem;

      @media screen and (max-width: 480px) {
        background-size: 50%;
        margin: 0rem 0.5rem;
      }
    }
  }

  h4.stepTitle {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 0;
    font-weight: bold;

    @media screen and (max-width: 480px) {
      line-height: 1.5rem;
    }
  }

  button {
    font-weight: 400;
    display: block;
    background-color: transparent;
    border: white solid 1px;
    border-radius: 3rem;
    font-family: "Zen Kaku Gothic New", sans-serif;
    color: white;
    padding: 0.4rem 1rem;
    font-size: 0.9rem;
    width: 12rem;
    text-transform: uppercase;

    @media screen and (max-width: 450px) {
      margin-bottom: 2rem;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:hover:enabled {
      background-color: #fff;
      color: #3c3c3c;
    }
  }

  .navButton {
    font-size: 0.8rem;
    position: absolute;
    top: 7rem;
    width: 5rem;
    left: 3rem;

    @media screen and (max-width: 768px) {
      top: 1.5rem;
      left: 1.5rem;
    }
  }

  .tzktLink {
    color: white;
  }
}

/* STEP 1 Raffle */

.row {
  display: flex;
  width: 90%;
  margin: auto;
  max-width: 1000px;

  .description {
    margin-bottom: 0.5rem;
  }

  >.left {
    width: 47%;
    padding: 2%;
  }

  >.right {
    width: 53%;
    padding: 0%;

    >div {
      margin-top: 0.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    // margin: 1rem;
    flex-direction: column;

    >.left {
      padding: 0%;
      width: 100%;
    }

    >.right {
      padding: 5%;
      width: 100%;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.raffleBox {
  .raffleBoxImage {
    width: 100%;
    padding: 45%;
    position: relative;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      width: 100%;
      height: 5rem;
    }

    background: url("images/raffle_idle.gif") no-repeat 50% 50%;
    background-size: contain;
  }
  
  .raffleBoxImage.win {
    background: url("images/win.gif") no-repeat 50% 50%;
    background-size: contain;
  }

  .raffleBoxGrid {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #575b59cc;
    padding: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: start;
    justify-content: center;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      /* width of the entire scrollbar */
    }
  }

  .ticket {
    background-image: url('./images/green_ticket.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 3.4rem;
    height: 2rem;
    margin: 0.5rem;
    animation: pop 100ms ease-in-out both;

    a {
      color: white;
      font-size: 0.7rem;
      font-family: 'Zen Kaku Gothic New', sans-serif;

      @media screen and (max-width: 480px) {
        font-size: 0.5rem;
      }
    }

    &.myTicket {
      background-image: url('./images/orange_ticket.svg');

    }
  }

  @keyframes pop {
    0% {
      transform: scale(0);
    }

    50% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(1);
    }
  }

  .caption {
    margin-top: 1rem;
  }
}

.amountInput {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  .disabled {
    opacity: 0.5;
  }

  .enabled {
    cursor: pointer;
  }

  svg {
    height: 2rem;
  }

  input {
    font-size: 0.9rem;
    text-align: center;
    width: 6rem;
    height: 2rem;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid white;
    font-family: 'Zen Kaku Gothic New', sans-serif;
    color: white;
    margin: 0 1rem;
    padding: 0.4rem 1rem;
  }
}

.raffleDesc {
  margin: 1.5rem 1.25rem;
  line-height: 1.25rem;

  li {
    text-align: left;
    margin-top: 0.8em;
    font-size: 0.8rem;
  }

}

.raffleButton {
  margin-top: 1rem;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background-color: #feb100;
    border: transparent solid 1px;
  }

  button:disabled {
    background-color: #aaabab;
    border: transparent solid 1px;
  }
}

.rafflePrizes {
  display: flex;
  flex-wrap: wrap;

  .prize {
    width: 25%;
    padding: 0.5rem;
    font-size: 0.75rem;

    img {
      width: 100%;
    }
  }

  .prizeName {
    font-weight: bold;
  }

  .prizeWinner {
    a {
      color: white;
    }
  }
}

// STEP 2 & 3
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading {
  height: 300px;
  width: 300px;
  background: url(./images/raffle_processing.gif) 50% 50% no-repeat;
  background-size: contain;
}

.loadingMessage {
  margin-bottom: 5rem;
}

.result {
  .bgPreview {
    height: 240px;
    width: 240px;
    border-radius: 100%;

    >img {
      border-radius: 100%;
    }
  }

  .resultIcon {
    height: 80px;
    width: 80px;

    svg {
      height: 100%;
      width: 100%;
    }

    margin-bottom: 1rem;
  }

  .resultMessage {
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 1rem;
  }

  button {
    margin-bottom: 1rem;
  }
}