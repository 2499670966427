.container {
  display: flex;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
  align-items: flex-start;
  width: 100%;
  justify-content: center;
}
.feature {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  @media screen and (max-width: 768px) {
    margin: 3rem 0;
    flex: auto;
  }
}
.title {
  font-size: 1.1rem;
  font-weight: bold;
  color: var(--textColor);
  margin: 1rem 0;
  @media screen and (max-width: 768px) {
    margin: 0.1rem 0;
  }
}
.desc {
  margin: 0px auto;
  color: var(--lightTextColor);
  max-width: 85%;
  p {
    margin: 0 0;
    font-size: 0.8rem;
    text-align: center;
    line-height: 1.2rem;
    @media screen and (max-width: 768px) {
      font-size: 0.9rem;
      line-height: 1.5rem;
    }
  }
}
.hint {
  font-weight: 600;
  color: #fb8000;
}
.image {
  width: 60%;
  max-width: 15rem;
  margin: 1rem auto;
  img {
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 40%;
  }
}
