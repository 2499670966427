.menuContainer {
  display: flex;
}
body {
  --textColor: #0a0a0a;
  --lightTextColor: #707070;
  --textColor: #3c3c3c;
  background-color: #aeddcf;
  letter-spacing: 0.05em;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: relative;

  @media screen and (max-width: 1366px) {
    margin-top: -7rem; //need to fix
  }
  @media screen and (max-width: 480px) {
    margin-top: -6rem; //need to fix
  }
}

.homeSection {
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  height: calc(100vh - 3rem);
  .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(174, 221, 207);
  }
  .butterfly {
    position: absolute;
    height: 5%;
    width: 5%;
    top: 15%;
    left: 80%;
    background-image: url(./images/butterfly.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .bee {
    position: absolute;
    height: 8%;
    width: 8%;
    top: 25%;
    left: 5%;
    background-image: url(./images/bee.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 3rem 0;
    .titleImage {
      width: 100%;
      max-width: 30.8rem;
      max-height: 100%;
    }
    .fomodate {
      font-size: 1rem;
      color: #08797f;
      font-weight: 500;
      margin-bottom: 0.8rem;
      @media screen and (max-width: 480px) {
        font-size: 0.6rem;
        padding: 0 0;
      }
      @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        padding: 0 0;
      }
    }
    h2 {
      font-size: 1.6rem;
      //letter-spacing: 0.1em;
      color: #08797f;
      margin-top: 1.5rem;
      margin-bottom: 0rem;
      font-weight: 700;
      @media screen and (max-width: 480px) {
        font-size: 1rem;
        padding: 0 0;
      }
      @media screen and (max-width: 768px) {
        font-size: 1.2rem;
        padding: 0 0;
      }
    }
    .point {
      padding: 0.5rem;
      font-size: 1.1rem;
      font-weight: 500;
      text-align: left;
      margin: 0 8%;
      line-height: 1.8rem;
      @media screen and (max-width: 1366px) {
        font-size: 1rem;
        margin-top: 0.05rem;
        margin: 0 15%;
      }
      @media screen and (max-width: 480px) {
        font-size: 0.8rem;
        margin: 0 0;
        padding-left: 1.5rem;
        line-height: 1.3rem;
      }
    }
    .stepstyle {
      @media screen and (max-width: 480px) {
        margin-left: 15%;
        margin-right: 15%;
        line-height: 1.5em;
      }
    }
    .underline {
      text-decoration: underline;
    }

    .hint {
      width: 100%;
      padding-top: 1rem;
      padding-left: 17%;
      color: red;
      text-align: left;
      font-size: 1rem;
      font-weight: 500;
      @media screen and (max-width: 1366px) {
        padding-top: 0.5rem;
        font-size: 1rem;
        margin: 0%;
        padding-left: 28%;
      }
      @media screen and (max-width: 480px) {
        padding-top: 0.5rem;
        font-size: 0.6rem;
        margin: 0%;
        padding-left: 23%;
      }
    }

    .hintpoint {
      color: red;
      font-size: 1rem;
      font-weight: 500;
      margin: 0 15%;
      text-align: left;
      @media screen and (max-width: 1366px) {
        padding-top: 0rem;
        font-size: 0.7rem;
        margin: 0 25%;
      }
      @media screen and (max-width: 480px) {
        padding-top: 0rem;
        font-size: 0.6rem;
        margin: 0 16%;
      }
    }

    p {
      padding: 0.5rem;
      line-height: 1.8em;
      font-size: 1.1rem;
      font-weight: 500;
      text-align: left;
      margin: 0% 5%;
      @media screen and (max-width: 1366px) {
        font-size: 1rem;
        margin-top: 0.1rem;
        margin: 0% 15%;
      }
      @media screen and (max-width: 768px) {
        font-size: 0.8em;
        margin-top: 0.1rem;
        margin-left: 1%;
        margin-right: 1%;
      }
      @media screen and (max-width: 480px) {
        font-size: 0.8em;
        margin-top: 0.1rem;
        margin-left: 3%;
        margin-right: 3%;
        line-height: 1.5em;
        text-align: center;
      }
    }
    .enrollButton {
      cursor: pointer;
      margin-top: 0.25rem;
      margin-bottom: 0.5rem;
      background: var(--textColor);
      border-radius: 100rem;
      border: none;
      color: #efefef;
      font-size: 0.8rem;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 480px) {
        font-size: 0.7rem;
      }
      span {
        padding: 1rem 1.5rem;
        letter-spacing: 0.1em;
      }
    }
    .enrollButton:disabled {
      opacity: 0.4;
      cursor: default;
    }
  }
}
.full {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: 0;
  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.titleDeco {
  max-width: 5rem;
  max-height: 5rem;
}
