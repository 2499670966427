.unsynchover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  background-color: white;
  color: black;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.unsynchover:hover {
  opacity: 1;
}
.SyncButton {
  position: relative;
}
button {
  cursor: pointer;
}
button:disabled{
  cursor:default;
}
