.alertOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 101;
  display: flex;
}
.alertBubble {
  background-color: #ffffffcc;
  color: var(--text-color);
  border-radius: 100rem;
  width: 100%;
  height: 3rem;
  max-width: 40rem;
  margin: 3rem auto;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 1rem;
  line-height: 1.2em;
  .alertLink {
    color: var(--textColor);
  }
}
.icon {
  margin: auto 1rem;
}
