.modalOverlay {
  background-color: #cccccc85;
  position: fixed;
  display: flex;
  top: 4rem;
  bottom: 0;
  left: 0%;
  right: 0;
  z-index: 10;
}
.CloseButton{
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  :hover{
    opacity: 0.5;
  }
  svg{
    height: 100%;
    width: 100%;
  }
}
.atmModal {
  position: relative;
  background-color: #575b59;
  color: white;
  border-radius: 2rem;
  height: 88%;
  max-width: 70%;
  width: 90rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem 5%;
  overflow-y: scroll;

  .decoRight,
  .decoLeft {
    z-index: 100;
    position: absolute;
    bottom: 0.5rem;
  }
  .decoLeft {
    background: url("images/human_left.png") no-repeat 50% 50%;
    background-size: contain;
    height: 8rem;
    width: 10rem;
    left: 0.25rem;
    @media screen and (max-width: 1368px) {
      background-size: 50%;
      height: 6rem;
      left: 0.25rem;
    }
    @media screen and (max-width: 1024px) {
      background-size: 60%;
      height: 6rem;
      left: 0.25rem;
    }
    @media screen and (max-width: 768px) {
      background: none;
      display: none;
    }
  }
  .decoRight {
    background: url("images/human_right.png") no-repeat 50% 50%;
    background-size: contain;
    height: 6rem;
    width: 10rem;
    right: 0.25rem;
    @media screen and (max-width: 1024px) {
      background-size: 60%;
      height: 4rem;
      right: 0.25rem;
    }
    @media screen and (max-width: 768px) {
      background: none;
      display: none;
    }
    @media screen and (max-width: 1368px) {
      background-size: 50%;
      height: 4rem;
      right: 0.25rem;
    }
  }
  &::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0000002a; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
  @media screen and (max-width: 1300px) {
    max-height: 95%;
    max-width: 90%;
    width: 90rem;
    border-radius: 1rem;
    flex-direction: column;
    margin: auto;
    @media screen and (max-width: 768px) {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
  h3.title {
    color: #e1f7f1;
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 480px) {
      font-size: 1.3rem;
      margin-top: 2.5rem;
    }

    .titledecoLeft {
      background: url("images/beer.png") no-repeat 50% 50%;
      height: 3rem;
      width: 3rem;
      margin: 0rem 1rem;
      @media screen and (max-width: 480px) {
        background-size: 50%;
        margin: 0rem 0.5rem;
      }
    }
    .titledecoRight {
      background: url("images/beer_2.png") no-repeat 50% 50%;
      height: 3rem;
      width: 3rem;
      margin: 0rem 1rem;
      @media screen and (max-width: 480px) {
        background-size: 50%;
        margin: 0rem 0.5rem;
      }
    }
  }
  h4.stepTitle {
    font-size: 1rem;
    margin: 0rem;
    font-weight: 400;
    @media screen and (max-width: 1024px) {
      font-size: 0.9rem;
      margin-bottom: 0.5rem;
    }
    @media screen and (max-width: 480px) {
      max-width: 70%;
      margin: 0px auto;
    }
  }
  button {
    font-weight: 400;
    display: block;
    background-color: transparent;
    border-radius: 3rem;
    border: white solid 1px;
    font-family: 'Zen Kaku Gothic New', sans-serif;
    color: white;
    padding: 0.4rem 1rem;
    font-size: 0.9rem;
    width: 12rem;
    text-transform: uppercase;

    &:disabled {
      opacity: 0.5;
    }

    &:hover:enabled {
      background-color: #fff;
      color: #3c3c3c;
    }
  }
  .amount {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    svg {
      margin-right: 0.5rem;
      height: 1.1rem;
      width: 1.1rem;
    }
  }

  .navButton {
    font-size: 0.8rem;
    position: absolute;
    top: 7rem;
    width: 5rem;
    left: 3rem;

    @media screen and (max-width: 768px) {
      top: 1.5rem;
      left: 1.5rem;
    }
  }

  .tzktLink {
    color: white;
  }
}

/* STEP 1 Select TDTM */

.TDTMList {
  // border: 1px solid red;
  display: flex;
  justify-content: center;
  // height: 110px;
  margin: auto auto 0rem auto;
  width: 100%;
  padding: 0 5rem;

  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }

  .TDTMCard {
    // height: 100px;
    margin: 1rem 1rem 0;
    cursor: pointer;
    img {
      border-radius: 2rem;
      // height: 80px;
      max-width: 100%;
      @media screen and (max-width: 1024px) {
        max-width: 90%;
      }
    }
    .title {
      font-weight: 500;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
      @media screen and (max-width: 1024px) {
        font-size: 1rem;
      }
    }
  }

  .amount {
    justify-content: center;
    margin-bottom: 0.5rem;
  }

  :global(.swiper-slide-active){
    .TDTMCard{
      img {
        border: white 4px solid;
      }
    }
  }

  :global(.swiper-slide:not(.swiper-slide-active)) {
    .amount,
    .title {
      opacity: 0.4;
    }
  }
  :global(.swiper-button-next:after),
  :global(.swiper-button-prev:after) {
    content: "";
    background-image: url("./images/navigation.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 1.5rem;
    height: 1.5rem;
  }

  :global(.swiper-button-prev:after) {
    transform: rotate(180deg);
  }
  :global(.swiper-button-next),
  :global(.swiper-button-prev) {
    background-color: #575b59;
    height: 100%;
    top: 0;
    margin-top: 0;
    width: 5rem;
    @media screen and (max-width: 768px) {
      width: 2rem;
    }
  }
  :global(.swiper-button-prev) {
    left: 0;
  }
  :global(.swiper-button-next) {
    right: 0;
  }
}
.control {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: auto;
  justify-content: center;

  .amount {
    margin-bottom: 2rem;
  }

  button {
    margin-bottom: 1rem;
    @media screen and (max-width: 1024px) {
      margin-bottom: 1rem;
      font-size: 0.8rem;
    }
  }
}
/* Step 2: enter amount */
.form {
  display: flex;
  flex-wrap: wrap;
  margin: auto 3%;
  margin-top: 1.2rem;
  @media screen and (max-width: 480px) {
    margin-top: 0rem;
  }

  .TDTMCard {
    margin: 2rem;
    .title {
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    img {
      width: 80%;
      border-radius: 2rem;
      @media screen and (max-width: 1024px) {
        width: 70%;
      }
      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
  }
  .left {
    width: 45%;
    @media screen and (max-width: 1024px) {
      width: 40%;
      padding: 1rem 1rem 1rem 1rem;
    }
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    @media screen and (max-width: 480px) {
      width: 100%;
      padding: 0;
    }
  }
  .right {
    width: 55%;
    padding: 3rem 2rem 2rem 2rem;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    @media screen and (max-width: 1400px) {
      padding: 1rem 1rem 1rem 1rem;
    }
    @media screen and (max-width: 1024px) {
      width: 60%;
      padding: 1rem 1rem 1rem 1rem;
    }
    @media screen and (max-width: 768px) {
      padding: 1rem;
      width: 100%;
    }
    @media screen and (max-width: 480px) {
      padding: 0rem;
    }

    .label {
      font-weight: 500;
      @media screen and (max-width: 1024px) {
        font-weight: 400;
        font-size: 0.9rem;
      }
    }

    .balance {
      display: flex;
      justify-content: space-between;
      padding: 0 20% 0 0;
      @media screen and (max-width: 768px) {
        width: 100%;
        flex-direction: column;
      }
      @media screen and (max-width: 480px) {
        flex-direction: row;
        padding: 0 10% 0 0;
      }

      .amount {
        align-self: flex-end;
      }
    }

    .input {
      grid-column: span 2;
      label {
        display: block;
        margin-bottom: 0.5rem;
      }
      input {
        position: relative;
        width: 60%;
        background: transparent;
        border: 2px solid white;
        color: white;
        font-family: 'Zen Kaku Gothic New', sans-serif;
        font-size: 1rem;
        padding: 1rem 1rem;
        line-height: 1rem;
      }
      > div {
        position: relative;
      }
      .max {
        font-size: 0.8rem;
        font-weight: normal;
        padding: 0 0;
        line-height: 1em;
        position: absolute;
        top: 0.6rem;
        bottom: 0.6rem;
        right: 5rem;
        width: 4rem;
        border-width: 2px;
        @media screen and (max-width: 1700px) {
          right: 4.5rem;
        }
        @media screen and (max-width: 1600px) {
          right: 4rem;
        }
        @media screen and (max-width: 1500px) {
          right: 3.5rem;
        }
        @media screen and (max-width: 1300px) {
          right: 5rem;
        }
        @media screen and (max-width: 1200px) {
          right: 4.5rem;
        }
        @media screen and (max-width: 1110px) {
          right: 4rem;
        }
        @media screen and (max-width: 968px) {
          right: 3.5rem;
        }
        @media screen and (max-width: 880px) {
          right: 2.5rem;
        }
        @media screen and (max-width: 763px) {
          right: 5rem;
        }
        @media screen and (max-width: 667px) {
          right: 4.5rem;
        }
        @media screen and (max-width: 615px) {
          right: 4rem;
        }
        @media screen and (max-width: 595px) {
          right: 3.5rem;
        }
        @media screen and (max-width: 475px) {
          right: 1rem;
        }
      }
      .warning {
        margin-top: 1rem;
        font-size: 0.8rem;
        position: absolute;
        @media screen and (max-width: 1024px) {
          font-size: 0.7rem;
          margin-right: 3rem;
        }
      }
    }
    .note {
    }
    button {
      width: 80%;
      @media screen and (max-width: 480px) {
        width: 100%;
      }
    }
    .submit button {
      margin-top: 5rem;
      @media screen and (max-width: 1024px) {
        margin-top: 4rem;
      }
      @media screen and (max-width: 768px) {
        margin-top: 5rem;
      }
    }
  }
}

/* Step 3: confirm transaction */
.graph {
  margin: auto 2rem;
  display: flex;
  margin-top: 4rem;
  @media screen and (max-width: 1400px) {
    margin-top: 2rem;
  }
  @media screen and (max-width: 1024px) {
    margin-top: 1rem;
  }
  @media screen and(max-width:768px) {
    flex-direction: column;
    width: 180px;
    margin: 2rem auto;
  }

  .mid {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 170px;
    @media screen and (max-width: 1024px) {
      width: 6%;
    }
    .amount {
      justify-content: center;
      font-size: 1.3rem;
      @media screen and (max-width: 1024px) {
        font-size: 1rem;
      }
    }
    .arrow svg {
      width: 100%;
    }
    .arrow.flip {
      transform: scaleX(-1);
    }

    @media screen and(max-width:768px) {
      margin-bottom: 0px;
      width: 100%;
      height: 80px;
      > div {
        display: flex;
        flex-direction: row-reverse;
      }
      .arrow.flip {
        transform: scaleY(-1);
      }
      .arrow svg {
        transform: rotate(90deg);
        width: 30px;
      }
    }
  }
  .right,
  .left {
    display: grid;
    width: 45%;
    grid-template-columns: 5rem 1fr;
    @media screen and (max-width: 1024px) {
      width: 47%;
    }
    @media screen and(max-width:768px) {
      width: 100%;
    }

    .label {
      text-align: left;
      margin-left: 7rem;
      margin-top: 0.5rem;
      @media screen and (max-width: 1500px) {
        margin-left: 4rem;
      }
      @media screen and (max-width: 1400px) {
        margin-left: 5rem;
      }
      @media screen and (max-width: 1024px) {
        margin-left: 6rem;
      }
      @media screen and (max-width: 480px) {
        margin-left: 0rem;
      }
      > div {
        height: 2rem;
      }
    }
    .balance {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .amount {
        min-width: 5rem;
        @media screen and (max-width: 1024px) {
          min-width: 4rem;
        }
      }
      @media screen and (max-width: 1024px) {
        margin-top: 0.5rem;
      }
    }

    .before {
      opacity: 0.5;
    }
  }
  .right {
    @media screen and(min-width:769px) {
      grid-template-columns: 1fr;
      padding-right: 5rem;
      .amount {
        grid-column: 1;
      }
      .label {
        display: none;
      }
    }
  }

  .TDTMCard {
    grid-column: span 2;
    @media screen and(min-width:769px) {
      margin-left: 5rem;
    }
    img {
      width: 180px;
      height: 180px;
      border-radius: 2rem;
      margin-bottom: 1rem;
    }
  }

  .wallet {
    @media screen and(max-width:768px) {
      grid-column: span 2;
    }
    .walletBackground {
      width: 180px;
      height: 180px;
      margin: 0 auto;
      margin-bottom: 1rem;
      border-radius: 2rem;
      background-color: #2a2a2a;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      svg {
        display: block;
      }
    }
  }
}
.confirm {
  margin: 1rem auto;
  margin-bottom: 5rem;
}

// STEP 4
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading {
  height: 150px;
  width: 150px;
  background: url(./images/processing.gif) 50% 50% no-repeat;
  background-size: contain;
  margin-bottom: 3rem;
}
.result {
  .resultIcon {
    height: 80px;
    width: 80px;
    svg {
      height: 100%;
      width: 100%;
    }
    margin-bottom: 1rem;
  }
  .resultMessage {
    margin-bottom: 8rem;
    font-weight: 500;
    font-size: 1rem;
  }
  button {
    margin-bottom: 1rem;
  }
}
