.header {
  position: absolute;
  height: 4rem;
  width: 100%;
  top: 0;
  z-index: 100;
  transition: 150ms;

  --backgroundColor: #38cb9e;
  &.fixed {
    position: fixed;
    animation: MoveUpDown 150ms;

    @keyframes MoveUpDown {
      0% {
        transform: translateY(-100%);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
  .headerBar {
    position: absolute;
    left: 0;
    right: 0;
    padding-left: 6rem;
    top: 0;
    width: 100%;
    height: 4rem;
    background-color: var(--backgroundColor);
    display: flex;
    @media screen and (max-width: 1024px) {
      flex-wrap: wrap;
      padding-left: 0;
      bottom: 0;
      padding-top: 4rem;
    }
  }
  .headerBar.transparent {
    background-color: transparent;
  }
}

.logo {
  // margin: 1rem;
  width: 6rem;
  height: 7rem;
  padding: 1rem;
  z-index: 100;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
  .hoverMessage {
    position: absolute;
    display: flex;
    align-items: center;
    justify-items: center;
    left: 6rem;
    text-align: center;
    background-color: white;
    color: var(--textColor);
    width: 11em;
    padding: 0.5em 1em;
    border-radius: 10em;
    top: 35%;
    bottom: 35%;
    opacity: 0;
    transition: 100ms;
  }
  a:hover .hoverMessage {
    opacity: 1;
  }
}

.menu {
  display: flex;
  margin-left: auto;
  max-width: 100%;
  height: 100%;
  @media screen and (max-width: 1024px) {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    flex-wrap: wrap;
  }
  a {
    display: flex;
    margin: auto 0.2rem;
    svg {
      height: 2.2rem;
      width: 2.2rem;
    }
  }
}
.SyncButton {
  border: none;
  height: 100%;
  display: flex;
  align-items: center;
  margin: auto 0.2rem;
  display: inline-flex;
  background-color: black;
  color: white;
  border-radius: 10rem;
  height: 2.2rem;
  padding: 0 1rem;
  align-items: center;
  font-weight: 900;
  cursor: pointer;
}
.menuContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // max-width: 36rem;
  width: 100%;
  min-height: 100%;
  margin-bottom: 3rem;
  .nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 43rem;
    width: 100%;
  }
  a {
    text-transform: uppercase;
    font-size: 1rem;
    border: none;
    font-weight: bold;
    color: var(--textColor);
    text-decoration: none;
    &.hover {
      opacity: 0.5;
      text-decoration: none;
    }
    &.active {
      color: white;
    }
  }
  svg {
    padding: 1px;
    height: 2.2rem;
    width: 2.2rem;
  }
  .expand {
    opacity: 1;
  }

  @media screen and (max-width: 1024px) {
    top: 4rem;
    transition: 100ms;
    flex-direction: column;
    .nav {
      flex-direction: column;
    }
    background-color: var(--backgroundColor);
    // transform: translateX(100%);
    transform: scaleY(0);
    transform-origin: top;
    padding: 2rem 0;
    a {
      margin: 1rem auto;
    }
    &.expand {
      transform: scaleY(1);
      // transform: translateX(0);
    }
  }
}
.expandButton {
  display: none;
  background-color: transparent;
  border: none;
  @media screen and (max-width: 1024px) {
    display: inline-block;
  }
  margin: auto 0.2rem;
  svg {
    padding: 1px;
    height: 2.2rem;
    width: 2.2rem;
  }
}

.menuContainer .community {
  display: flex;
  margin-left: auto;
  max-width: 100%;
  height: 100%;
  @media screen and (max-width: 1024px) {
    margin: 1rem auto;
  }
  a {
    display: flex;
    margin: auto 0.2rem;
    svg {
      height: 2.2rem;
      width: 2.2rem;
    }
  }
}
