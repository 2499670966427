.row {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    max-width: 50rem;
    width: 100%;

    .left {
        width: 50%;
    }

    .right {
        width: 50%;

    }

    @media screen and (max-width: 768px) {

        div & .left,
        div & .right {
            width: 100%;
            margin: 1rem auto;
        }
    }
}

h3.title {
    color: #e1f7f1;
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 480px) {
        font-size: 1.3rem;
        margin-top: 2.5rem;
    }

    .titledecoLeft {
        background: url("images/title_img.png") no-repeat 50% 50%;
        height: 3rem;
        width: 3rem;
        margin: 0rem 1rem;

        @media screen and (max-width: 480px) {
            background-size: 50%;
            margin: 0rem 0.5rem;
        }
    }

    .titledecoRight {
        background: url("images/title_img.png") no-repeat 50% 50%;
        height: 3rem;
        width: 3rem;
        margin: 0rem 1rem;
        transform: scaleX(-1);

        @media screen and (max-width: 480px) {
            background-size: 50%;
            margin: 0rem 0.5rem;
        }
    }
}

h4.stepTitle {
    font-weight: bold;

    @media screen and (max-width: 480px) {
        font-size: 1rem;
        text-align: center;
    }

}

b.hightlight {
    font-weight: 800;
    color: #f7b426;

}

button.backButton {
    font-size: 0.8rem;
    position: absolute;
    top: 7rem;
    width: 8rem;
    left: 3rem;
    background-color: #3c3c3c;
    border-color: transparent;

    @media screen and (max-width: 1024px) {
        width: 5rem;
        left: 1rem;
    }
}


// }

/* Welcome */
.selectRoom {
    display: flex;
    text-align: left;
    font-size: 1rem;
    line-height: 2rem;

    // flex-direction: column;
    // align-items: center;
    a {
        color: #FFF;
        text-decoration: none;
    }

    .stepTitle {
        margin: 1.5rem auto;
        text-align: center;
        font-weight: bold;
        font-size: 1.1rem;
        text-align: center;
    }

    .roomType {
        justify-content: space-around;
    }

    .room {
        cursor: pointer;
        // margin: 1rem;
    }

    .thumbnail {
        width: 200px;
        height: 200px;
        // border: 2px #efefefce solid;
        // padding: 10px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:hover .roomOverlay {
            background-color: #efefef74;
            color: #3c3c3c;
            font-weight: 500;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 2rem;
        }
    }

    .roomName {
        text-align: center;
        margin: 0.5rem auto 1rem;
        font-weight: 500;
    }
}


.redeemRoom {

    .right {
        display: flex;
        flex-direction: column;
    }

    .description {
        line-height: 1.5rem;
    }

    ul {
        text-align: left;
        margin: 0 0 0 0;
    }

    .redeemButton {
        margin: 1rem auto;
    }

    .checkVoucher,
    .checkTDTM,
    .checkTDG {
        margin: 1rem 0.5rem;
        height: 2rem;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;

        svg {
            margin: auto;
            width: 1.2rem;
        }
    }

    .checkTDTM {
        background-image: url('./images/tdtm.png');
        width: 2rem;
    }

    .checkTDG {
        background-image: url('./images/tdg.png');
        width: 2rem;
    }

    .checkVoucher {
        background-image: url('./images/voucher.png');
        width: 4rem;
    }

    .disabled {
        filter: grayscale(100%);
    }
}

.quantity {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 1rem 0;

    &.disabled {
        pointer-events: none;
    }

    &:not(.disabled) :disabled {
        opacity: 0.5;
    }

    p {
        width: 100%;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }

    input {
        background-color: transparent;
        border: 2px #e1f7f1 solid;
        color: white;
        padding: 0.5rem;
        margin: 0 1rem;
        text-align: center;
        width: 5rem;
        font-size: 0.8rem;
    }

    button.inputButton {
        width: auto;
        height: 2rem;
        border: none;
        position: relative;
        padding: 0;

        &:hover {
            background-color: transparent;
        }
    }

    svg {
        // display: block;
        height: 1rem;
    }
}

.warningMessage {
    color: #f56b6c;
    font-size: 0.8em;
}

.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        text-align: center;
    }
}

.loading {
    height: 150px;
    width: 150px;
    background: url(./images/processing.gif) 50% 50% no-repeat;
    background-size: contain;
    margin-bottom: 3rem;
}

.result {
    .resultIcon {
        height: 80px;
        width: 80px;

        svg {
            height: 100%;
            width: 100%;
        }

        margin-bottom: 1rem;
    }

    .resultMessage {
        margin-bottom: 2rem;
        max-width: 50rem;
        font-weight: 500;
        font-size: 1rem;

        .tzktLink {
            color: white;
        }
    }

    button {
        margin-bottom: 1rem;
    }
}