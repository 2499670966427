.accordion {
  margin-bottom: 2.5rem;
  --iconSize: 2rem;
  --iconMargin: 1rem;
}

.toggle {
  display: flex;
  cursor: pointer;
  transition: 150ms;
  align-items: center;

  &:hover {
    opacity: 0.75;
  }
  .icon {
    align-self: center;
    // margin-left: auto;
    position: relative;
    height: var(--iconSize);
    min-height: var(--iconSize);
    width: var(--iconSize);
    min-width: var(--iconSize);
    transition: 150ms;
    transform: rotate(0deg);
    * {
      fill: var(--decoColor) !important;
      stroke-width: 1px !important;
    }
  }
  &.opened {
    .icon {
      transform: rotate(90deg)!important;
      * {
        fill: var(--secondDecoColor) !important;
        stroke-width: 1px !important;
      }
    }
  }
}
.header {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: left;
  color: var(--textColor);
  margin-left: var(--iconMargin);
  .titleImage {
    padding-right: 0.5rem;
  }
  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
}

.content {
  border-top: 1px solid var(--border-color);
  margin-top: 0.5rem;
  margin-left: calc(var(--iconSize) + var(--iconMargin));
  // padding-top: 0.5rem;
  text-align: left;
  color: var(--lightTextColor);

  p {
    margin: 0;
    @media screen and (max-width: 768px) {
      font-size: 0.9rem;
    }
  }
}
