.DozenVerseContainer {
  --text-color: #3c3c3c;
  --border-color: #707070;
  color: var(--text-color);
  padding: 2.5rem 0 0rem;
  margin: 0rem 0 1rem;
  margin-bottom: -1.5rem;
  height: calc(100vh - 8rem);
  min-height: 800px;

  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}

.welcomeRow {
  display: flex;
  height: 100%;
  margin-bottom: -5rem;

  .left {
    width: 24rem;
    padding: 2rem;
  }

  .right {
    width: calc((100% - 24rem));

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: wrap;

    .left,
    .right {
      width: 100%;

      iframe {
        height: 440px;
      }
    }
  }
}

h2 {
  color: #3c3c3c;
  font-size: 2em;
  font-weight: 900;
  margin-top: 3rem;

  @media screen and (max-width: 768px) {
    font-size: 1.5em;
  }
}

.header {
  display: flex;
  border-bottom: var(--border-color) 2px solid;
  align-items: center;
  padding: 0.5rem 0;

  h4 {
    font-size: 1.25rem;
    margin: 0 0.5rem;
  }

  svg {
    height: 1.2rem;
  }
}

.panelLeft {
  position: absolute;
  left: 0;
  top: 200px;
  width: 15rem;
  background-color: white;
  z-index: 10;
  border-radius: 0 1rem 1rem 0;
  box-shadow: 0 3px 6px #00000029;

  p {
    text-align: left;
    margin: 0 0 0 0;
  }

  a {
    color: var(--text-color);
  }

  padding: 3rem 0;

  @media screen and (max-width: 768px) {
    padding: 2rem 0 1rem;
    position: relative;
    border-radius: 1rem 1rem 0 0;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    width: auto;
    margin-bottom: -5rem;
    // padding: 1rem 0rem 2rem;
  }
}

.TDTMCarousel {
  .slide {
    // width: calc(100% / 4);
    width: 100%;
  }

  .title {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 0 0.5rem 0;
  }
}

.TDTMCard {
  cursor: pointer;
  border-radius: 1rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1 1 0 px;
  // background-color: white;
  margin: 5% 15%;

  @media (max-width: 768px) {
    margin: 0;
    // height: 100px;
  }

  img {
    border: white 6px solid;
    border-radius: 1rem;

    @media (max-width: 768px) {
      // margin: 15% 15%;
      height: 100px;
      width: 100px;
      max-width: 100px;
    }
  }

  .clickable {
    position: absolute;
    top: 3rem;
    bottom: 3rem;
    left: 3rem;
    right: 3rem;
  }
}

.guestCard .image{
  border: white 6px solid;
  border-radius: 1rem;
  width:100%;
  padding: 45% 45%;
  
  @media (max-width: 768px) {
    // margin: 15% 15%;
    padding: 0 0;
    height: 100px;
    width: 100px;
    max-width: 100px;
  }
  background-image: url(./img/guest_icon.png);
  background-size: cover;
}

.addrInfo {
  >div {
    margin: 1rem 2rem;
  }

  @media screen and (max-width: 768px) {
    display: flex;

    >div {
      width: 50%;
      margin: 1rem 1rem;
    }
  }
}

.panelRight {
  >div {
    width: 100%;
  }

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  .header {
    justify-content: center;
    margin-bottom: 3rem;
  }

  width: 100%;
}

.image {
  max-width: 16.5rem;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    //width: 80%;
  }

  @media screen and (max-width: 480px) {
    max-width: 16.7rem;
  }

  img {
    width: 100%;
    display: block;
  }
}

.floor {
  position: absolute;
  bottom: 0;
  top: 745px;
  left: 0;
  right: 0;
  background-color: #9caaa4;

  @media screen and (max-width: 768px) {
    top: 725px;
  }
}

.EventCarousel {
  
  @media screen and (max-width:768px) {

    :global(.swiper-button-next:after),
    :global(.swiper-button-prev:after) {
      content: "";
      background-image: url("./img/navigation.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      width: 1.5rem;
      height: 1.5rem;
    }

    :global(.swiper-button-prev:after) {
      transform: rotate(180deg);
    }

    :global(.swiper-button-next),
    :global(.swiper-button-prev) {
      height: 100%;
      top: 0;
      margin-top: 0;
      width: 5rem;
      @media screen and (max-width: 768px) {
        width: 2.5rem;
      }
    }

    :global(.swiper-button-prev) {
      //left: 0.rem;
      padding-left:0.75rem;
    }

    :global(.swiper-button-next) {
      //right: 0;
      padding-right:0.75rem;
    }
  }

  .parallaxBg {
    width: 1900px;
    position: absolute;
    height: 100%;

    .deco1 {
      position: absolute;
      background-image: url("./img/dozenverse_deco1.png");
      background-size: contain;
      background-repeat: no-repeat;
      left: 335px;
      top: 100px;
      height: 5rem;
      width: 5rem;
    }

    .deco2 {
      position: absolute;
      background-image: url("./img/dozenverse_deco2.png");
      background-size: contain;
      background-repeat: no-repeat;
      left: 715px;
      top: 100px;
      height: 5rem;
      width: 5rem;
    }

    .deco3 {
      position: absolute;
      background-image: url("./img/dozenverse_deco3.png");
      background-size: contain;
      background-repeat: no-repeat;
      left: 2350px;
      top: 80px;
      height: 7rem;
      width: 7rem;
    }

    .deco4 {
      position: absolute;
      background-image: url("./img/dozenverse_deco4.png");
      background-size: contain;
      background-repeat: no-repeat;
      left: 2520px;
      top: 10px;
      height: 5.7rem;
      width: 5.7rem;
    }

    .deco5 {
      position: absolute;
      background-image: url("./img/dozenverse_deco5.png");
      background-size: contain;
      background-repeat: no-repeat;
      left: 2500px;
      top: 130px;
      height: 6rem;
      width: 6rem;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }

  .slide {
    // height: 1000px;
    // border: var(--text-color) 1px solid;
    width: 380px;
    position: relative;

    // @media (max-width: 768px) {
    //   width: 100%;
    // }
    img {
      height: 444px;
      display: block;
    }
  }

  :global(.swiper-button-next::after),
  :global(.swiper-button-prev::after) {
    content: "";
    // background-image: url("./img/navigation.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 3rem;
    height: 3rem;
  }

  :global(.swiper-button-prev::after) {
    transform: rotate(180deg);
    left: 0;
  }

  :global(.swiper-button-prev) {
    left: 0;
  }

  :global(.swiper-button-next) {
    right: 0;
  }

  color: #07787f;

  .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 0.5rem 0 1rem 0;
  }
}

.SyncButton {
  border-width: 0;
  position: relative;
  // bottom: 0rem;
  left: auto;
  right: auto;
  display: inline-block;
  border-radius: 100rem;
  background-color: #feb100;
  margin: 1rem auto 3rem;
  padding: 0.5rem 2rem;
  color: white;
  font-size: 0.9rem;
  font-family: "Zen Kaku Gothic New", sans-serif;

  &:disabled {
    background-color: #979e9b;

    a {
      text-decoration: underline;
    }
  }
}

.EnterButton {
  border-width: 0;
  position: relative;
  bottom: 5rem;
  left: auto;
  right: auto;
  display: inline-block;
  border-radius: 100rem;
  background-color: #feb100;
  margin: auto 0;
  padding: 0.5rem 2rem;
  color: white;
  font-size: 0.9rem;
  font-family: "Zen Kaku Gothic New", sans-serif;

  &:disabled {
    background-color: #979e9b;

    a {
      text-decoration: underline;
    }
  }
}

/* IRL Modal */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(198, 208, 204, 0.7);
  z-index: 2000;
  display: flex;
  padding: 1rem;

  .closeButton {
    // background-color: aqua;
    text-align: right;
    font-size: 2rem;
    padding-top: 1rem;
    padding-right: 1.5rem;

    .buttonText {
      color: #e1f7f1;
    }

    a {
      text-decoration: none;
      color: var(--text-color);
    }
  }
}

.modal {
  background-color: #575b59;
  width: 100%;
  border-radius: 1rem;
}
