.modalOverlay {
  background-color: #cccccc85;
  position: fixed;
  display: flex;
  top: 4rem;
  bottom: 0;
  left: 0%;
  right: 0;
  z-index: 10;

  .CloseButton {
    position: absolute;
    top: 2rem;
    right: 2rem;
    height: 2rem;
    width: 2rem;
    cursor: pointer;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  .decoRight,
  .decoLeft {
    z-index: 100;
    position: absolute;
    bottom: 6%;
  }

  .decoLeft {
    background: url("images/deco_left.png") no-repeat 50% 50%;
    background-size: contain;
    height: 8rem;
    width: 10rem;
    left: 16%;

    @media screen and (max-width: 1300px) {
      background-size: contain;
      height: 6rem;
      left: 5%;
    }

    @media screen and (max-width: 1024px) {
      background-size: contain;
      height: 6rem;
    }

    @media screen and (max-width: 768px) {
      background: none;
      display: none;
    }

    @media screen and (min-width: 2400px) {
      left: calc(50% - 43rem);
    }
  }

  .decoRight {
    background: url("images/deco_right.png") no-repeat 50% 50%;
    background-size: contain;
    height: 5rem;
    width: 13rem;
    right: 18%;

    @media screen and (max-width: 1024px) {
      background-size: contain;
      height: 4rem;
    }

    @media screen and (max-width: 768px) {
      background: none;
      display: none;
    }

    @media screen and (max-width: 1300px) {
      background-size: contain;
      height: 4rem;
      right: 6%;
    }

    @media screen and (min-width: 2000px) {
      right: calc(50% - 43rem);
    }
  }
}

.bgModal {
  position: relative;
  background-color: #575b59;
  color: white;
  border-radius: 2rem;
  height: 88%;
  max-width: 70%;
  width: 90rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  // padding: 2rem 5%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
    /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0000002a;
    /* color of the scroll thumb */
    border-radius: 20px;
    /* roundness of the scroll thumb */
  }

  @media screen and (max-width: 1300px) {
    max-height: 95%;
    max-width: 90%;
    width: 90rem;
    border-radius: 1rem;
    flex-direction: column;
    margin: auto;

    @media screen and (max-width: 768px) {
      padding-left: 5%;
      padding-right: 5%;
    }
  }

  h3.title {
    color: #e1f7f1;
    font-size: 1.8rem;
    margin-top: 3rem;
    margin-bottom: 0.8rem;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 480px) {
      font-size: 1.3rem;
      margin-top: 2.5rem;
    }

    .titledecoLeft {
      background: url("images/title_left.png") no-repeat 50% 50%;
      height: 3rem;
      width: 3rem;
      margin: 0rem 1rem;

      @media screen and (max-width: 480px) {
        background-size: 50%;
        margin: 0rem 0.5rem;
      }
    }

    .titledecoRight {
      background: url("images/title_right.png") no-repeat 50% 50%;
      height: 3rem;
      width: 3rem;
      margin: 0rem 1rem;

      @media screen and (max-width: 480px) {
        background-size: 50%;
        margin: 0rem 0.5rem;
      }
    }
  }

  h4.stepTitle {
    font-size: 1rem;
    font-weight: 400;
  }

  button {
    font-weight: 400;
    display: block;
    background-color: transparent;
    border-radius: 3rem;
    border: white solid 1px;
    font-family: "Zen Kaku Gothic New", sans-serif;
    color: white;
    padding: 0.4rem 1rem;
    font-size: 0.9rem;
    width: 12rem;
    text-transform: uppercase;

    @media screen and (max-width: 450px) {
      margin-bottom: 2rem;
    }

    &:disabled {
      opacity: 0.5;
    }

    &:hover:enabled {
      background-color: #fff;
      color: #3c3c3c;
    }
  }

  .amount {
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.5rem;

    svg {
      margin-right: 0.5rem;
      height: 1.1rem;
      width: 1.1rem;
    }
  }

  .navButton {
    font-size: 0.8rem;
    position: absolute;
    top: 7rem;
    width: 5rem;
    left: 3rem;

    @media screen and (max-width: 768px) {
      top: 1.5rem;
      left: 1.5rem;
    }
  }

  .tzktLink {
    color: white;
  }
}

/* STEP 1 Welcome */
.welcomeMessage {
  display: flex;
}

.welcomeDeco {
  width: 24rem;

  @media screen and (max-width: 768px) {
    width: 100%;
    height: 5rem;
  }

  background: url("images/npc.png") no-repeat 50% 50%;
  background-size: contain;
  margin-right: 2rem;
  margin-top: 3rem;
}

.welcomeText {
  margin-top: 0.5rem;
  text-align: left;
  line-height: 1.5em;

  h4.stepTitle {
    font-weight: bold;
    margin-left: 1rem;
  }

  li {
    margin-top: 0.8em;
    font-size: 0.9rem;
  }
}

.startButton {
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* STEP 2 Select TDTM */
.row {
  display: flex;
  width: 90%;
  margin: auto;
  max-width: 1000px;

  .description {
    margin-bottom: 0.5rem;
  }

  >.left {
    width: 56.5%;
    padding: 5%;
  }

  >.right {
    width: 43.5%;
    padding: 5%;

    >div {
      margin-top: 0.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    // margin: 1rem;
    flex-direction: column;

    >.left {
      padding: 0%;
      width: 100%;
    }

    >.right {
      padding: 5%;
      width: 100%;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.TDTMList {
  // border: 1px solid red;
  display: flex;
  justify-content: center;
  // height: 110px;
  margin: auto auto 0rem auto;
  width: 100%;
  padding: 0 1rem;

  @media screen and (max-width: 768px) {
    padding: 0 2rem;
  }

  .TDTMCard {
    // height: 100px;
    margin: 5% 5% 0;
    cursor: pointer;

    .clickable {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    .notClickable {
      padding: 0.85rem;
      background-color: #575b59ab;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 450px) {
        font-size: 0.6rem;
      }
    }

    .notClickable svg {
      width: 100%;
    }

    img {
      border-radius: 1rem;
      // height: 80px;
      max-width: 100%;

      @media screen and (max-width: 1024px) {
        max-width: 90%;
      }
    }

    .title {
      font-weight: 500;
      font-size: 0.8rem;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;

      @media screen and (max-width: 1024px) {
        font-size: 1rem;
      }
    }
  }

  // :global(.swiper-slide-active) {
  .TDTMCard.selected {
    img {
      border: white 3px solid;
    }

    // }
  }

  .TDTMCard:not(.selected) {
    // :global(.swiper-slide:not(.swiper-slide-active)) {
    .amount,
    .title {
      opacity: 0.4;
    }
  }

  :global(.swiper-button-next:after),
  :global(.swiper-button-prev:after) {
    content: "";
    background-image: url("./images/navigation.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 1.5rem;
    height: 1.5rem;
  }

  :global(.swiper-button-prev:after) {
    transform: rotate(180deg);
  }

  :global(.swiper-button-next),
  :global(.swiper-button-prev) {
    background-color: #575b59;
    height: 100%;
    top: 0;
    margin-top: 0;
    width: 1rem;

    @media screen and (max-width: 768px) {
      width: 2rem;
    }
  }

  :global(.swiper-button-prev) {
    left: 0;
  }

  :global(.swiper-button-next) {
    right: 0;
  }
}

.bgPreview {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.bg1 {
  background-image: url("./images/BG_1.png");
}

.bg2 {
  background-image: url("./images/BG_2.png");
}

.bg3 {
  background-image: url("./images/BG_3.png");
}

.previewTDTM {
  display: block;
  width: 100%;
}

.bgNA {
  background-color: rgba(0, 0, 0, 0.164);
  height: 0;
  padding: 50% 0;
}

.bgChoose {
  display: flex;
  padding: 0 1rem;

  >div {
    cursor: pointer;
    width: calc(100% / 3);
    margin: calc(5% / 3);
    height: 0;
    padding: calc(45% / 3) 0;
    border: transparent solid 3px;
    border-color: rgb(255, 255, 255);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    opacity: 0.5;
  }

  .selected {
    opacity: 1;
  }
}

.preview {
  width: 100%;
}

.changeButton {
  margin-top: 1rem;
  margin-bottom: 5rem;
}

.endedNote {
  text-align: center;
  font-size: 0.8rem;
}

/* Step 3: confirm transaction */
.graph {
  margin: 1rem 2rem;
  display: flex;
  margin-top: auto;

  @media screen and (max-width: 1400px) {
    margin-top: auto;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 1rem;
  }

  @media screen and(max-width:768px) {
    flex-direction: column;
    width: 240px;
    margin: 2rem auto;
  }

  .mid {
    width: 10%;
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1024px) {
      width: 6%;
    }

    .amount {
      justify-content: center;
      font-size: 1.3rem;

      @media screen and (max-width: 1024px) {
        font-size: 1rem;
      }
    }

    .arrow svg {
      width: 100%;
    }

    .arrow.flip {
      transform: scaleX(-1);
    }

    @media screen and(max-width:768px) {
      margin-bottom: 0px;
      width: 100%;
      height: 80px;

      >div {
        display: flex;
        flex-direction: row-reverse;
      }

      .arrow.flip {
        transform: scaleY(-1);
      }

      .arrow svg {
        transform: rotate(90deg);
        width: 30px;
      }
    }
  }

  .right,
  .left {
    // display: grid;
    width: 45%;
    grid-template-columns: 5rem 1fr;

    @media screen and (max-width: 1024px) {
      width: 47%;
    }

    @media screen and(max-width:768px) {
      width: 100%;
    }

    .preview {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 1024px) {
        margin-top: 0.5rem;
      }
    }
  }

  .right {
    @media screen and(min-width:769px) {
      grid-template-columns: 1fr;
      padding-right: 5rem;

      .amount {
        grid-column: 1;
      }

      .label {
        display: none;
      }
    }
  }

  .left .TDTMCard {
    margin-top: 0.5rem;

    @media screen and(min-width:769px) {
      margin-left: 5rem;
    }

    img {
      width: 240px;
      height: 240px;
      border-radius: 0rem;
      // margin-bottom: 1rem;
    }
  }

  .preview {
    @media screen and(max-width:768px) {
      grid-column: span 2;
    }

    .newBackground {
      width: 240px;
      height: 240px;
      margin: 0 auto;
      margin-bottom: 1rem;
      border-radius: 0rem;
      position: relative;

      svg {
        display: block;
      }
    }
  }
}

.confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;

  @media screen and(max-width:768px) {
    margin-bottom: 5rem;
  }

  p {
    text-align: center;
  }

  button {
    margin: 1rem auto;
  }

  .notice {
    font-size: 0.9rem;
    font-weight: 400;
  }
}

// STEP 4
.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading {
  height: 150px;
  width: 150px;
  background: url(./images/processing.gif) 50% 50% no-repeat;
  background-size: contain;
  margin-bottom: 3rem;
}

.result {
  .bgPreview {
    height: 240px;
    width: 240px;
    border-radius: 100%;

    >img {
      border-radius: 100%;
    }
  }

  .resultIcon {
    height: 80px;
    width: 80px;

    svg {
      height: 100%;
      width: 100%;
    }

    margin-bottom: 1rem;
  }

  .resultMessage {
    margin-bottom: 3rem;
    font-weight: 500;
    font-size: 1rem;
  }

  button {
    margin-bottom: 1rem;
  }
}