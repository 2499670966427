.header {
  position: absolute;
  height: 7rem;
  width: 100%;
  top: 0;
  z-index: 100;
  transition: 150ms;

  --backgroundColor: #38cb9e;
  &.fixed {
    position: fixed;
    animation: MoveUpDown 150ms;

    @keyframes MoveUpDown {
      0% {
        transform: translateY(-100%);
      }
      100% {
        transform: translateY(0);
      }
    }
  }
  .headerBar {
    position: absolute;
    left: 0;
    right: 0;
    padding-left: 6rem;
    top: 0;
    width: 100%;
    height: 4rem;
    background-color: var(--backgroundColor);
    display: flex;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      padding-left: 0;
      bottom: 0;
      padding-top: 4rem;
    }
  }
}

.logo {
  // margin: 1rem;
  width: 6rem;
  height: 7rem;
  padding: 1rem;
  position: relative;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.menu {
  display: flex;
  margin-left: auto;
  max-width: 100%;
  height: 100%;
  @media screen and (max-width: 768px) {
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    flex-wrap: wrap;
  }
  a {
    display: flex;
    margin: auto 0.2rem;
    svg {
      height: 2.2rem;
      width: 2.2rem;
    }
  }
}
.SyncButton {
  border: none;
  height: 100%;
  display: flex;
  align-items: center;
  margin: auto 0.2rem;
  display: inline-flex;
  background-color: black;
  color: white;
  border-radius: 10rem;
  height: 2.2rem;
  padding: 0 1rem;
  align-items: center;
  font-weight: 900;
  cursor: pointer;
}
.menuContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // max-width: 36rem;
  width: 100%;
  min-height: 100%;
  margin-bottom: 3rem;
  .nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 36rem;
    width: 100%;
  }
  a {
    text-transform: uppercase;
    font-size: 1rem;
    border: none;
    font-weight: bold;
    color: var(--textColor);
    text-decoration: none;
    &.hover {
      opacity: 0.5;
      text-decoration: none;
    }
    &.active {
      color: white;
    }
  }
  .expand {
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    top: 4rem;
    transition: 100ms;
    flex-direction: column;
    .nav {
      flex-direction: column;
    }
    background-color: var(--backgroundColor);
    // transform: translateX(100%);
    transform: scaleY(0%);
    transform-origin: top;
    padding: 2rem 0;
    a {
      margin: 1rem auto;
    }
    &.expand {
      transform: scaleY(100%);
      // transform: translateX(0);
    }
  }
}
.expandButton {
  display: none;
  @media screen and (max-width: 768px) {
    display: inline-block;
  }
}

.footer {
  position: relative;
  background-color: #e1f7f1;
  text-align: center;
  display: flex;
  .centered {
    margin: 0 auto 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
      margin-bottom: 8rem;
    }
  }
  .logo {
    width: 100%;
    max-width: 330px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.ipfs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  > div {
    display: flex;
    flex-direction: column;
    margin: 0 2rem 1.5rem;
    align-items: center;
    > div {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
      a img {
        height: 2.5rem;
        margin: 0.2rem;
      }
    }
  }
}
.community {
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  top: auto;
  display: flex;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    right: 50%;
    transform: translateX(50%);
  }
  a {
    display: flex;
    margin: auto 0.2rem;
    svg {
      height: 2.2rem;
      width: 2.2rem;
    }
  }
}
.credit {
  position: absolute;
  left: 1rem;
  bottom: -1.5rem;
  top: auto;
  display: flex;
  max-width: 100%;
  @media screen and (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%) translateY(-200%);
    margin-top: 2rem;
  }
  a {
    color: var(--textColor); //#08797f;
    text-decoration: none;
    border-bottom: 1px solid var(--textColor);
    display: flex;
    margin: auto 0.2rem;
  }
}

a:hover {
  opacity: 0.5;
}
