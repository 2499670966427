.menuContainer {
  display: flex;
  background-color: #fff;
}
body {
  --textColor: #3c3c3c;
  --lightTextColor: #707070;
  --textColor: #3c3c3c;
  letter-spacing: 0.05em;
}

p {
  line-height: 2em;
  //text-align: justify;
  @media screen and (max-width: 768px) {
    font-size: 0.9em;
  }
}

h2 {
  color: #3c3c3c;
  font-size: 2em;
  font-weight: 900;
  margin-top: 3rem;
  @media screen and (max-width: 768px) {
    font-size: 1.5em;
  }
}
.keyFeatures {
  @media screen and (max-width: 768px) {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
}
.content {
  position: relative;
  margin-bottom: 3rem;
  // z-index: 100;
}
.homeSection {
  height: calc(100vh - 3rem);
  .decoLeft,
  .decoRight {
    width: 35%;
    max-width: 600px;
    min-width: 200px;
  }
  .AkaSwapIcon {
    svg {
      height: 0.6em;
    }
  }
  @media (max-width: 480px) {
    .decoLeft {
      width: 100%;
      transform: translateX(-50%);
    }
    .decoRight {
      width: 100%;
      transform: translateX(50%);
    }
  }

  :global(.cls-1) {
    fill: none;
    stroke: #fff;
  }
}
.faqSection {
  --backgroundColor: #f6f6f6;
  --decoColor: #fb8000;
  --secondDecoColor: #feb100;
  h2 {
    margin-top: 0rem;
  }
}
.timelineSection {
  --backgroundColor: #bdeadc;
  h2 {
    margin-top: 0rem;
  }
}
div.completedSection {
  background-color: #fff;
  .completedBg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url("./images/mint_bg.jpg");
    opacity: 0.4;
  }
  .completedContent {
    z-index: 10;
    position: relative;
    height: 100%;
    > div {
      display: flex;
      height: 100%;
      margin: 0 auto;
    }
  }
  .completed {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 1rem;
    padding: 1rem 1.5rem;
    margin: auto auto;
    a {
      color: #3c3c3c;
      font-weight: 900;
    }
    p {
      font-size: 1.1rem;
      font-weight: 500;
      line-height: 1.2rem;
    }
  }
}
.clubSection {
  p {
    margin: 0 18%;
    font-size: 1 rem;
    font-weight: 500;
    line-height: 1.8rem;
    text-align: center;
    @media screen and (max-width: 768px) {
      font-size: 0.9rem;
      margin: 0 5%;
    }
  }
}

.mintSection {
  --backgroundColor: #24abc2;
  .title {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
    margin-top: -3rem;
    img {
      height: 3rem;
      display: block;
    }
  }
  P {
    font-size: 0.9rem;
    line-height: 1.5rem;
    margin: 5% 8%;
    font-weight: 500;
  }
  h2 {
    color: var(--textColor);
    font-size: 2rem;
    margin-bottom: 0;
    @media screen and (max-width: 1366px) {
      font-size: 1.8rem;
    }
    @media screen and (max-width: 768px) {
      font-size: 1.5rem;
    }
    @media screen and (max-width: 480px) {
      font-size: 1.2rem;
    }
  }
  .titleDeco {
    padding: 0% 2%;
    padding-top: 2%;
  }
}

.aboutSection {
  --backgroundColor: #e1f7f1;
  h2 {
    color: var(--textColor);
  }
  p {
    text-align: justify;
    font-size: 1rem;
    line-height: 2rem;
    @media screen and (max-width: 480px) {
      margin: 0 6%;
      font-size: 0.9rem;
      line-height: 1.5rem;
      font-weight: 400;
      text-align: justify;
    }
  }
  .team {
    img {
      @media screen and (max-width: 768px) {
        width: 120%;
      }
    }
    p {
      font-size: 0.9rem;
      line-height: 1.8rem;
      padding: 0 3%;
      @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        padding: 0 3%;
      }
    }
  }
  .socialIcon {
    svg {
      height: 1.5em;
      margin-left: 0.25em;
      transform: translateY(0.3em);
      path {
        stroke-width: 0px;
      }
    }
  }
  .keyFeatures {
  }
}
.gallerySection {
  --backgroundColor: #f6f6f6;
  .titleDeco {
    max-width: 2rem;
    max-height: 2rem;
    margin: 0px 20px;
  }
  // .team {
  //   img {
  //     @media screen and (max-width: 768px) {
  //       width: 120%;
  //     }
  //   }
  //   p {
  //     font-size: 0.9rem;
  //     font-weight: 500;
  //     line-height: 1.5rem;
  //     padding: 0 10%;
  //     @media screen and (max-width: 768px) {
  //       font-size: 0.8rem;
  //       padding: 0 3%;
  //     }
  //   }
  // }
  // .socialIcon {
  //   svg {
  //     height: 1.5em;
  //     margin-left: 0.25em;
  //     transform: translateY(0.3em);
  //     path {
  //       stroke-width: 0px;
  //     }
  //   }
  // }
}
.homeSection {
  //background-image: url(./images/bg.jpg);
  background-position: 50% 50%;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  .bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #36cb9f;
    //background-image: url(./images/bg.jpg);
    background-position: 50% 50%;
    background-size: cover;
    // background-attachment: fixed;
    // position: relative;
  }
  .mouse {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: url(./images/mouse_1.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 100%;
  }
  .butterfly {
    position: absolute;
    height: 5%;
    width: 5%;
    top: 15%;
    left: 80%;
    background-image: url(./images/butterfly.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  .bee {
    position: absolute;
    height: 8%;
    width: 8%;
    top: 25%;
    left: 5%;
    background-image: url(./images/bee.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    @media screen and (max-width: 768px) {
      top: 10%;
    }
  }

  .text {
    position: relative;
    .titleImage {
      width: 100%;
      max-width: 35rem;
      max-height: 100%;
      @media screen and (max-width: 768px) {
        max-width: 30rem;
      }
      @media screen and (max-width: 480px) {
        max-width: 20rem;
      }
    }
    h2 {
      font-size: 1.5rem;
      //letter-spacing: 0.1em;
      color: var(--textColor);
      margin-top: 2;
      padding: 0 29%;
      @media screen and (max-width: 480px) {
        font-size: 0.6rem;
        padding: 0 0;
      }
      @media screen and (max-width: 768px) {
        font-size: 1rem;
        padding: 0 10%;
      }
    }
    .scrollButton {
      background: none;
      border: none;
      color: white;
      font-size: 1.2rem;
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      animation: MoveUpDown 3s ease-in-out infinite;
      text-decoration: none;
      @media screen and (max-width: 480px) {
        font-size: 1rem;
      }
      @keyframes MoveUpDown {
        0%,
        40%,
        80% {
          transform: translateY(0);
        }
        20%,
        60% {
          transform: translateY(10px);
        }
      }
      span {
        margin: 5rem 0 0.5rem;
        letter-spacing: 0.05em;
      }
      svg {
        height: 2.5rem;
      }
    }
  }
}
.full {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: 0;
  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.titleDeco {
  max-width: 5rem;
  max-height: 5rem;
}
.decoLeft,
.decoRight {
  position: absolute;
  bottom: 0;
  max-width: 18%;
  z-index: 0;
  @media screen and (max-width: 480px) {
    max-width: 15%;
  }
  @media screen and (max-width: 1366px) {
    max-width: 15%;
  }
  @media screen and (max-width: 768px) {
    max-width: 15%;
  }
}
.decoLeft {
  left: 0;
}
.decoRight {
  right: 0;
}
.comingSoon {
  display: inline-block;
  border-radius: 100rem;
  background-color: #feb100;
  margin: 2rem 0;
  padding: 0.5rem 2rem;
  color: white;
}
