.pageContainer {
  margin-top: 3rem;
  min-height: 100%;
  padding-top: 3rem;
  &.top {
    margin-top: 0;
    padding-top: 0;
  }
}

.Container {
  margin-right: auto;
  margin-left: auto;
  max-width: 60rem;
  padding: 2rem;
  &.larger {
    max-width: 100rem;
  }
  &.smaller {
    max-width: 45rem;
  }
  @media screen and (max-width: 768px) {
    max-width: 60rem;
  }
  @media screen and (max-width: 480px) {
    padding: 1rem;
    width: 100%;
  }
}
.Section {
  background-color: var(--backgroundColor);
  padding: 4rem 0;
  position: relative;
  &.full {
    height: 100vh;
    padding: 4rem 0 0;
    min-height: 100vh;
  }
}
