.row {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 100%;
    align-items: center;
    max-width: 900px;

    .left {
        width: 65%;
    }

    .right {
        width: 35%;

    }

    h3 {
        text-align: center;
        margin: 0 auto;
    }

    .steps {
        width: 30%;

        h3 {
            text-align: left;
            margin-bottom: 0rem;
        }

        p {
            height: 8rem;
            line-height: 1.4rem;
            // margin-bottom: 0.8rem;

            @media screen and (max-width: 480px) {
                height: 5rem
            }
        }
    }

    .Arrow {
        width: 3%;
        margin-top: 8rem;

        svg {
            width: 100%;
        }
    }

    @media screen and (max-width: 768px) {

        div & .left,
        div & .right {
            width: 100%;
            margin: 1rem auto;
        }

        .steps {
            width: 100%;
        }

        .Arrow {
            transform: rotate(90deg);
            width: 1.5rem;
            margin: 1rem auto;
        }
    }
}


button.bottomButton {
    background-color: #feb100;
    border: none;
    font-weight: bold;
    margin: 0.75rem auto;
}


h3.title {
    color: #e1f7f1;
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 480px) {
        font-size: 1.3rem;
        margin-top: 2.5rem;
    }

    .titledecoLeft {
        background: url("images/dessert.png") no-repeat 50% 50%;
        background-size: contain;
        height: 3rem;
        width: 3rem;
        margin: 0rem 1rem;

        @media screen and (max-width: 480px) {
            background-size: 50%;
            margin: 0rem 0.5rem;
        }
    }

    .titledecoRight {
        background: url("images/dessert.png") no-repeat 50% 50%;
        background-size: contain;
        height: 3rem;
        width: 3rem;
        margin: 0rem 1rem;

        @media screen and (max-width: 480px) {
            background-size: 50%;
            margin: 0rem 0.5rem;
        }
    }
}

.right {
    @media screen and (max-width: 480px) {
        justify-content: center;
        flex-direction: row;
        min-height: 0;

    }

    display: flex;
    justify-content: left;
    flex-direction: column;
    min-height: 45vh;


    .qrcode {
        margin-top: auto;
        margin-left: 1rem;
        display: flex;

        @media screen and (max-width: 800px) {
            justify-content: center;
            width: 50%;
        }

        img {
            width: 5.5rem;
        }

        a {
            font-weight: bold;
            text-align: center;
            color: white;
            text-decoration: none;
        }

        margin-bottom: 1.5rem;
    }
}


.left {
    .step {
        display: flex;
        text-align: left;
        margin-bottom: 1rem;
        padding: 0 3rem;
        font-size: 0.9rem;

        @media screen and (max-width: 800px) {
            padding: 0 1rem;
            margin: auto;
        }

        .icon {
            margin-right: 2rem;
        }
    }
}

.imageZoom {
    // smax-height: 20rem;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 800px) {
        margin: 0 2rem;
    }

    img {
        max-width: 100%;
        max-height: 20rem;
    }
}

.TDTMList {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 750px;



    .thumbnail,
    .TDTMCard {
        position: relative;
        width: calc(100% / 12);

        @media screen and (max-width: 800px) {
            width: calc(100% / 6);
        }

        padding: 0.25rem;
        opacity: 0.4;

        .clickable {
            cursor: pointer;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }

        img {
            border-radius: 0.5rem;
            // height: 80px;
            max-width: 100%;
            width: calc(100% / 1);
            height: calc(100% / 1);
        }
    }

    .TDTMCard.selected {
        img {
            border: white 3px solid;
        }
    }
}