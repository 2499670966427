.menuContainer {
  display: flex;
  background-color: #fff;
}
body {
  --textColor: #3c3c3c;
  --lightTextColor: #707070;
  --textColor: #3c3c3c;
  letter-spacing: 0.05em;
}

p {
  line-height: 2em;
  //text-align: justify;
  @media screen and (max-width: 768px) {
    font-size: 0.9em;
  }
}

h2.title {
  color: #3c3c3c;
  font-size: 2em;
  font-weight: 900;
  margin-top: 3rem;
  @media screen and (max-width: 768px) {
    font-size: 1.3em;
  }
}
.content {
  position: relative;
  margin-bottom: 3rem;
}
.gallerySection {
  --backgroundColor: #f6f6f6;
  .titleDeco {
    max-width: 2rem;
    max-height: 2rem;
    margin: 0px 20px;
  }
}

.titleDeco {
  max-width: 5rem;
  max-height: 5rem;
}
.decoLeft,
.decoRight {
  position: absolute;
  bottom: 0;
  max-width: 18%;
  z-index: 0;
  @media screen and (max-width: 480px) {
    max-width: 15%;
  }
  @media screen and (max-width: 1366px) {
    max-width: 15%;
  }
  @media screen and (max-width: 768px) {
    max-width: 15%;
  }
}
.decoLeft {
  left: 0;
}
.decoRight {
  right: 0;
}
