.modalOverlay {
  background-color: #cccccc85;
  position: fixed;
  display: flex;
  top: 4rem;
  bottom: 0;
  left: 0%;
  right: 0;
  z-index: 10;
}
.CloseButton{
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  :hover{
    opacity: 0.5;
  }
  svg{
    height: 100%;
    width: 100%;
  }
}
.modal {
  position: relative;
  background-color: #575b59;
  color: white;
  border-radius: 2rem;
  height: 88%;
  // max-width: 70%;
  width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 2rem 5%;
  overflow-y: scroll;
  .white &{
    background-color: white;
    color: #3c3c3c;
    border-radius: 0;
  }
  &::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0000002a; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
  @media screen and (max-width: 1300px) {
    max-height: 95%;
    // max-width: 90%;
    width: 1000px;
    border-radius: 1rem;
    flex-direction: column;
    margin: auto;
    @media screen and (max-width: 1024px) {
      width: 750px;
    }
    @media screen and (max-width: 768px) {
      max-width: 90%;
      padding-left: 5%;
      padding-right: 5%;
    }
  }
  button {
    font-weight: 400;
    display: block;
    background-color: transparent;
    border-radius: 3rem;
    border: white solid 1px;
    font-family: 'Zen Kaku Gothic New', sans-serif;
    color: white;
    padding: 0.4rem 1rem;
    font-size: 0.9rem;
    width: 12rem;
    text-transform: uppercase;

    &:disabled {
      opacity: 0.5;
    }

    &:hover:enabled {
      background-color: #fff;
      color: #3c3c3c;
    }
  }

}
.white .CloseButton svg{
  circle, line, g{
    stroke: #3c3c3c!important;
    stroke-width: 1!important;
  }
}