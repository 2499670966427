.decoRight,
.decoLeft {
    z-index: 100;
    position: absolute;
    bottom: 0rem;
}



.decoLeft {
    background: url("images/deco_left.png") no-repeat 50% 50%;
    background-size: contain;
    height: 4rem;
    width: 12rem;
    left: 2rem;

    @media screen and (max-width: 1368px) {
        background-size: 60%;
        height: 4rem;
        left: 0.25rem;
    }

    @media screen and (max-width: 1024px) {
        background-size: 60%;
        height: 4rem;
        left: 0.25rem;
    }

    @media screen and (max-width: 768px) {
        background: none;
        display: none;
    }

    @media screen and (max-height: 835px) {
        background: none;
        display: none;
    }
}

.decoRight {
    background: url("images/deco_right.png") no-repeat 50% 50%;
    background-size: contain;
    height: 6rem;
    width: 10rem;
    right: 0.25rem;

    @media screen and (max-width: 1024px) {
        background-size: 60%;
        height: 4rem;
        right: 0.25rem;
    }

    @media screen and (max-width: 768px) {
        background: none;
        display: none;
    }

    @media screen and (max-width: 1368px) {
        background-size: 50%;
        height: 4rem;
        right: 0.25rem;
    }

    @media screen and (max-height: 835px) {
        background: none;
        display: none;
    }
}


.row {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    max-width: 50rem;
    width: 100%;

    .left {
        width: 50%;
    }

    .right {
        width: 50%;

    }



    @media screen and (max-width: 768px) {

        // .irlModal & .left,
        // .irlModal & .right {
        //     width: 100%;
        // }
        div & .left,
        div & .right {
            width: 100%;
        }
    }
}

// .irlModal {
// max-width: 50rem;
// width: 100%;
// height: 100%;
// margin: 0 auto;

// position: relative;
h3.title {
    color: #e1f7f1;
    font-size: 1.8rem;
    margin-bottom: 0.8rem;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 480px) {
        font-size: 1.3rem;
        margin-top: 2.5rem;
    }

    .titledecoLeft {
        background: url("images/title_img.png") no-repeat 50% 50%;
        height: 3rem;
        width: 3rem;
        margin: 0rem 1rem;

        @media screen and (max-width: 480px) {
            background-size: 50%;
            margin: 0rem 0.5rem;
        }
    }

    .titledecoRight {
        background: url("images/title_img.png") no-repeat 50% 50%;
        height: 3rem;
        width: 3rem;
        margin: 0rem 1rem;
        transform: scaleX(-1);

        @media screen and (max-width: 480px) {
            background-size: 50%;
            margin: 0rem 0.5rem;
        }
    }
}

h4.stepTitle {
    font-weight: bold;

    @media screen and (max-width: 480px) {
        font-size: 1rem;
        text-align: center;
    }

}

b.hightlight {
    font-weight: 800;
    color: #f7b426;

}

button.bottomButton {
    display: inline-block;
    background-color: #feb100;
    color: white;
    font-weight: bold;
    border-color: transparent;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
}

button.cartButton {
    font-size: 0.8rem;
    position: absolute;
    top: 7rem;
    width: 4rem;
    right: 3rem;
    border: transparent;

    @media screen and (max-width: 1024px) {
        // width: 3rem;
        right: 1rem;
    }

    svg {
        height: 1.5rem;
    }

    .totalQuantity {
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 1rem;
        font-size: 0.8rem;
        font-weight: bold;
        background-color: #e15454;
        color: white;
        position: absolute;
        top: 0;
        right: 0;
    }

    &:hover:enabled {
        background-color: transparent;
        opacity: 0.5;
    }
}

button.backButton {
    font-size: 0.8rem;
    position: absolute;
    top: 7rem;
    width: 8rem;
    left: 3rem;
    background-color: #3c3c3c;
    border-color: transparent;

    @media screen and (max-width: 1024px) {
        width: 5rem;
        left: 1rem;
    }
}


// }

/* Welcome */
.welcome {
    text-align: left;
    display: flex;
    // flex-direction: column;
    // align-items: center;

    .left {
        width: 35%;
        min-height: 300px;
        background-position: 50% 50%;
        background-image: url('./images/product-image.png');
        background-size: contain;
        background-repeat: no-repeat;
    }

    .right {
        width: 65%;
        padding-left: 3%;

        li {
            font-size: 1rem;
            line-height: 1.5em;
            padding-bottom: 0.5em;
            padding-left: 1%;

            @media screen and (max-width: 480px) {
                font-size: 0.8rem;
            }

        }
    }

    .stepTitle {
        font-weight: bold;
        font-size: 1.1rem;
        text-align: center;
    }

    .checkTDG {
        background-color: #737c78;
        display: inline-flex;
        align-items: center;
        font-size: 0.8rem;
        font-weight: 500;
        border-radius: 2rem;
        margin: 0 0.4rem;
        padding: 0 0.4rem;

        svg {
            margin-right: 0.4rem;
            width: 0.8rem;
        }

        &.checked {
            background-color: #32a165;
        }
    }

}

/* Select product */
.products {
    justify-content: space-around;

    .product {
        margin: 1rem;
    }

    .clickable {
        cursor: pointer;

        &:hover .productImage {
            border: 2px #ffffff solid;
        }
    }

    .productImage {
        width: 200px;
        height: 200px;
        border: 2px #efefefce solid;
        padding: 10px;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .productOverlay {
            background-color: #3b3b3bce;
            color: #ffffff;
            font-weight: 500;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 2rem;
        }
    }

    .productName {
        margin-top: 0.5rem;
        font-weight: 500;
    }
}

/* select TDTM */
.productDetail {
    .left {
        width: 50%;
    }

    .right {
        width: 50%;
        text-align: left;
        padding-left: 2rem;
        display: flex;
        flex-direction: column;
    }



    .shirtMockup {
        padding: 0 0.5rem;

        >div {
            background-image: url('./images/TD-shirt_back3.png');
            background-size: cover;
            width: 100%;
            padding: 33.3% 0;
            position: relative;
        }

        .realExampleTDTM {
            //border-radius: 9%;
            border-top-left-radius: 9%;
            border-top-right-radius: 9%;
            position: absolute;
            top: 24.6%;
            left: 10.1%;
            width: 26.1%;
        }

        .realExampleTDTM2 {
            //border-radius: 10%;
            border-top-left-radius: 11%;
            border-top-right-radius: 11%;
            position: absolute;
            top: 38%;
            left: 74.7%;
            width: 5.5%;
        }
    }

    .shotMockup {
        padding: 0 0.5rem;

        >div {
            background-image: url('./images/realExample.png');
            background-size: cover;
            width: 100%;
            padding: 33.3% 0;
            position: relative;
        }

        .realExampleTDTM {
            position: absolute;
            top: 50%;
            left: 40.7%;
            width: 15%;
        }
    }

    .thumbnails .TDTMList {
        // border: 1px solid red;
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        // height: 110px;
        width: 100%;
        padding: 0 2rem;

        .thumbnail,
        .TDTMCard {
            // height: 100px;
            margin: 5% 5% 0;
            position: relative;

            .clickable {
                cursor: pointer;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }

            .notClickable {
                padding: 0.85rem;
                background-color: #575b59ab;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.7em;
                padding-bottom: 4rem;

                @media screen and (max-width: 450px) {
                    font-size: 0.6rem;
                }
            }

            .notClickable svg {
                width: 80%;
            }

            img {
                border-radius: 0.5rem;
                // height: 80px;
                max-width: 100%;

                @media screen and (max-width: 1024px) {
                    max-width: 90%;
                }

            }

            .title {
                font-weight: 500;
                font-size: 0.8rem;
                margin-bottom: 0.25rem;
                margin-top: 0.25rem;

                @media screen and (max-width: 1024px) {
                    font-size: 0.8rem;
                }

            }
        }

        .TDTMCard.selected {
            img {
                border: white 3px solid;
            }

            // }
        }

        // :global(.swiper-slide-active) {
        //     .TDTMCard {
        //         img {
        //             border: white 3px solid;
        //         }
        //     }
        // }

        .TDTMCard:not(.selected) {
            // :global(.swiper-slide:not(.swiper-slide-active)) {

            .amount,
            .title {
                opacity: 0.4;
            }
        }

        :global(.swiper-button-next:after),
        :global(.swiper-button-prev:after) {
            content: "";
            background-image: url("./images/navigation.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            width: 1rem;
            height: 1rem;
        }

        :global(.swiper-button-prev:after) {
            transform: rotate(180deg);
        }

        :global(.swiper-button-next),
        :global(.swiper-button-prev) {
            background-color: #575b59;
            height: 100%;
            top: 0;
            margin-top: 0;
            width: 2rem;

            @media screen and (max-width: 768px) {
                width: 2rem;
            }
        }

        :global(.swiper-button-prev) {
            left: 0;
        }

        :global(.swiper-button-next) {
            right: 0;
        }
    }

    .imageZoom {
        // smax-height: 20rem;

        img {
            max-width: 100%;
            max-height: 20rem;
        }
    }

    .thumbnails {}

    .productName {
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 0.5em;
    }

    .productDesc {
        p {
            font-size: 0.8em;
            line-height: 1.5em;
        }

        margin-top: 1rem;
        //margin-bottom: auto;
    }

    .sizeChart {
        display: block;
        width: 100%;
    }

    .productOptions {
        display: flex;
        flex-wrap: wrap;

        p {
            width: 100%;
            margin-top: 1rem;
            margin-bottom: 0.5rem;
        }

        button {
            display: block;
            width: auto;
            margin: 0.2rem;
            border-radius: 0;
            font-size: 0.6rem;


            &:first-of-type {
                margin-left: 0;
            }

            &:hover {
                opacity: 0.6;
            }

            &.selected {
                background-color: white;
                color: #3c3c3c;
            }
        }
    }

    .productQuantity {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p {
            width: 100%;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }

        input {
            background-color: transparent;
            border: 2px #e1f7f1 solid;
            color: white;
            padding: 0.5rem;
            margin: 0 1rem;
            text-align: center;
            width: 5rem;
            font-size: 0.8rem;
        }

        button {
            width: auto;
            height: 2rem;
            border: none;
            position: relative;
            padding: 0;

            &:hover {
                background-color: transparent;
            }
        }

        svg {
            // display: block;
            height: 1rem;
        }
    }

}

.warningMessage {
    color: #f56b6c;
    font-size: 0.8em;
}

.cartList {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    text-align: left;

    h5 {
        font-size: 1rem;
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .cartItem,
    .total,
    .subtotal {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
    }




    svg {
        margin-right: 1rem;
        width: 2rem;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }

    .itemPrice {
        margin-left: auto;
        padding-left: 0.5rem;
        width: 6rem;
        text-align: right;
    }

    hr {
        margin: 1rem 0;
    }

    .note {
        font-size: 0.8rem;
        padding-top: 1rem;
        margin-top: auto;
    }
}

.shippingForm {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 1rem;

    input {
        background-color: transparent;
        border: none;
        border-bottom: 2px #e1f7f1 solid;
        padding: 0.5rem;
        margin-bottom: 0.5rem;
        color: white;
    }

    .select {
        background-color: #575b59;
        border: 2px solid #e1f7f1;
        margin: 0.5rem 0;
        font-size: 0.8rem;
        color: #bccec9;
        padding: 0.2rem 1rem;
        outline: none;
    }

    .value {
        margin-bottom: 0.5rem;
        color: #bccec9;
    }

    .confirmed {
        display: flex;
        align-items: baseline;

        input {
            margin: 0;
            display: block;
            margin-right: 0.5rem;
            border: 2px white solid;
        }
    }
}

.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        text-align: center;
    }
}

.loading {
    height: 150px;
    width: 150px;
    background: url(./images/processing.gif) 50% 50% no-repeat;
    background-size: contain;
    margin-bottom: 3rem;
}

.result {
    .resultIcon {
        height: 80px;
        width: 80px;

        svg {
            height: 100%;
            width: 100%;
        }

        margin-bottom: 1rem;
    }

    .resultMessage {
        margin-bottom: 2rem;
        max-width: 50rem;
        font-weight: 500;
        font-size: 1rem;
    }

    button {
        margin-bottom: 1rem;
    }
}