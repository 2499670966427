.container {
  display: flex;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
  align-items: flex-start;
  width: 100%;
  justify-content: center;
}

/* Tab */
.galleryTabBar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 3rem auto;
  @media screen and (max-width: 1750px) {
    max-width: 900px;
  }
  @media screen and (max-width: 910px) {
    max-width: 600px;
  }
  @media screen and (max-width: 480px) {
    margin: 1rem auto;
  }
}
.galleryTab {
  display: inline-block;
  background-color: transparent;
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-size: 1rem;
  position: relative;
  height: 2rem;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
    height: 2rem;
  }

  .tabIcon {
    height: 1.5rem;
    position: absolute;
    bottom: 0;
    left: 0.6rem;
  }
  &.iconPadding {
    padding-left: 2.5rem;
  }
  border-radius: 3rem;
  border: #7c8481 1px solid;
  &:hover {
    opacity: 0.7;
  }
  padding: 0 1rem;
  margin: 0.5rem;
  display: flex;
  color: #7c8481;
  font-weight: bold;
}
.selected {
  border: transparent 1px solid;
  background-color: #2db48f;
  color: white;
}

/* Panel */
.galleryRow {
  width: 100%;
  display: flex;
}
.sidebarOverlay {
  @media screen and (max-width: 768px) {
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    width: auto;
    z-index: 1;
    transition: 200ms;
    &.hide {
      width: auto;
      background-color: transparent;
    }
    width: 100vw;
    background-color: #00000033;
  }
}
.sidebarToggle {
  display: none;
  position: absolute;
  right: 1rem;
  top: 4rem;
  padding: 0.5rem;
  color: #3c3c3c;
  z-index: 1000;
  border-radius: 0 1rem 1rem 0;
  border-bottom: 0px;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  background-color: var(--dropdown-color);
  height: auto;
  svg {
    max-height: 1rem;
    display: inline-block;
  }
  @media screen and (max-width: 768px) {
    display: block;
    .hide & {
      right: -3rem;
      top: 60px;
      svg {
        max-height: 2rem;
      }
    }
  }
}
.sidebar {
  @media screen and (max-width: 768px) {
    transition: 150ms;
    width: 100%;
    max-width: 320px;
    //background-color: #a3e5d3;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 1rem 1rem 0 0;
    z-index: 100;
    .hide & {
      transform: translateX(-100%);
    }
  }
  width: 340px;
  top: 5rem;
}
.galleryPanel {
  margin-top: 1rem;
  margin-right: 2rem;
  text-align: left;
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  h3 {
    margin-top: 0rem;
  }
  @media screen and (max-width: 768px) {
    margin-top: 3rem;
    border-radius: 0rem;
    width: 100%;
    background-color: #f3f3f3;
  }
}
.gallerySearchBox {
  position: relative;
  margin-bottom: 2rem;
  @media screen and (max-width: 768px) {
    margin-bottom: 1rem;
  }
  input {
    -webkit-appearance: none;
    width: 100%;
    border: transparent;
    background-color: transparent;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1rem;
    border-radius: 4rem;
    background-color: #e1f7f1;
    font-family: "Zen Kaku Gothic New", sans-serif;
  }
  .SearchIcon {
    cursor: pointer;
    svg {
      position: absolute;
      right: 0.6rem;
      bottom: 0.6rem;
      height: 1.2rem;
    }
  }
  display: block;
}
.activeFilters {
  margin: 0rem 0.5rem 0.5rem;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
}
.clearFilter {
  display: inline-block;
  color: #3c3c3c;
  cursor: pointer;
  margin-left: 0.5rem;
  font-weight: 500;
  &:hover {
    opacity: 0.8;
  }
  margin-top: 9px;
}
.activeFilter {
  display: inline-flex;
  background-color: #e7e6e6;
  border-radius: 3rem;
  color: #3c3c3c;
  cursor: pointer;
  margin: 0 0.2rem 0.5rem 0.2rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  height: 2.5rem;
  align-items: center;
  line-height: 1.2rem;
  &:hover {
    opacity: 0.8;
  }
  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
}
.FilterIcon {
  svg {
    position: absolute;
    max-height: 1.2rem;
  }
}
.galleryOptions {
  background-color: #e2e2e248;
  border-radius: 1rem;
  @media screen and (max-width: 768px) {
    background-color: #f3f3f3;
    padding-bottom: 10rem;
  }
  margin: 0 -1.5rem;
  padding: 0 1.5rem;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0000002a; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
}
.galleryOptions .accordion {
  &:last-child {
    border-bottom: none;
  }
  > div:first-child {
    padding: 0.5rem 0;
    --iconSize: 1.5rem;
    --iconMargin: 2rem;
    svg {
      order: 1;
      margin-left: auto;
      height: 1rem;
      fill: #3c3c3c;
      stroke: transparent !important;
      transform: rotate(180deg);
    }
    .filterIcon {
      transform: rotate(180deg);
      padding-left: 0.5rem;
      display: inline-block;
    }
    div {
      margin-left: 0;
      font-size: 1rem;
      @media screen and (max-width: 768px) {
        font-size: 0.9rem;
      }
    }
  }
  > div:last-child {
    margin-left: 0;
  }
  border-bottom: 1.5px solid #3c3c3c44;
  margin-bottom: 0rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
.StatusOptions {
  // display: flex;
  margin-bottom: 2rem;
}
.status {
  font-size: 0.9rem;
  cursor: pointer;
  border-radius: 3rem;
  border: #3c3c3c 1px solid;
  padding: 0.25rem 1rem;
  width: max-content;
  margin-bottom: 0.5rem;
}
.status.active {
  background-color: #3c3c3c;
  color: white;
}
.option {
  appearance: none;
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 1rem;
  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
  font-weight: 500;
  font-family: "Zen Kaku Gothic New", sans-serif;
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  line-break: anywhere;
  &::before {
    content: " ";
    margin-right: 0.5rem;
    display: inline-block;
    border-radius: 0.5rem;
    background-color: rgba(0, 0, 0, 0.089);
    height: 1.5rem;
    width: 1.5rem;
  }
  &.active::before {
    background-color: #197b61;
  }
  .rarity {
    font-size: 0.8em;
    border-radius: 1em;
    margin-left: auto;
    height: 1.5rem;
    padding: 0 0.5em;
    border: 1px #7c8481 solid;
    min-width: fit-content;
  }
}

/* Grid and item */
.galleryResult {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem 0;
}
.galleryGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
.galleryItem {
  width: 20%;
  @media screen and (max-width: 1600px) {
    width: 25%;
  }
  @media screen and (max-width: 1080px) {
    width: 50%;
  }
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .info {
    font-size: 1rem;
    font-weight: bold;
    color: #3c3c3c;
    margin: 0.5rem 0 1rem;
    @media screen and (max-width: 480px) {
      font-size: 0.8rem;
    }
    .name {
      width: 9rem;
      max-width: 95%;
      padding-bottom: 0.5rem;
      margin: auto;
      border-bottom: 1px #7c8481 solid;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        display: block;
        margin-left: 4px;
        height: 1rem;
      }
    }
    .price {
      font-size: 0.8rem;
      display: inline-block;
      margin: 0 0.1rem;
      font-weight: bold;
      &.notforsale {
        opacity: 0.5;
      }
    }
  }
  .image {
    width: 90%;
  }
  svg {
    margin: 0 0.1em;
    height: 0.6rem;
  }
}
.note {
  margin: 1rem 0;
  p {
    margin: 0 0;
    text-align: left;
    line-height: 1.2rem;
    font-size: 0.75rem;
  }
}
.clickable {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.image {
  position: relative;
  border-radius: 1rem;
  margin: 0rem auto 0;
  border: 1px #7c8481 solid;
  box-shadow: 0 3px 6px #00000029;
  overflow: hide;
  img {
    border-radius: calc(1rem - 1px);
    width: 100%;
    height: 100%;
    display: block;
  }
}

/* Modal */
.modalOverlay {
  background-color: #00000085;
  position: fixed;
  display: flex;
  top: 4rem;
  bottom: 0;
  left: 0%;
  right: 0;
  z-index: 10;
}
.galleryModal {
  position: relative;
  background-color: #fcfcfc;
  border-radius: 2rem;
  max-height: 88%;
  max-width: 70%;
  margin: auto;
  display: flex;
  padding: 4rem 5%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 5px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0000002a; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
  @media screen and (max-width: 1300px) {
    max-height: 95%;
    max-width: 90%;
    border-radius: 1rem;
    flex-direction: column;
    padding: 5% 15%;
    margin: auto;
    @media screen and (max-width: 768px) {
      padding-left: 5%;
      padding-right: 5%;
    }
  }
  // inset: 20%;
  // max-width: 50vh;
  // width: 300px;
  .left,
  .right {
    width: 50%;
    height: 100%;
    @media screen and (max-width: 1300px) {
      width: 100%;
    }
  }

  .right {
    .image {
      max-width: 450px;
    }
  }
}
.info {
  margin-bottom: 2rem;
  .tokenName {
    font-size: 1.2rem;
    font-weight: bold;
    color: #3c3c3c;
    margin: 1rem 0 1rem;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
  .mainAttr {
    display: inline-block;
    margin: 0 1rem;
    color: #197b61;
    font-weight: 500;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
  }
}
.attrTable {
  display: grid;
  grid-template-columns: auto 1fr 3rem 4.5rem;
  text-align: left;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 2rem 3rem;
  }
  .attrName {
    text-align: right;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    color: #3c3c3cb6;
    font-weight: 500;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    @media screen and (max-width: 768px) {
      text-align: left;
      grid-column: span 3;
      font-size: 0.9rem;
      margin-top: 1rem;
      margin-left: 0rem;
      margin-bottom: -0.2rem;
    }
  }
  .attrValue {
    color: #197b61;
    font-weight: 500;
    margin-bottom: 0.75rem;
    max-width: 100%;
    overflow: hidden;
    overflow-wrap: break-word;
    @media screen and (max-width: 768px) {
      font-size: 0.9rem;
      // margin-left: 1rem;
    }
  }
  .attrAmount {
    background-color: #7ab2a3;
    border-radius: 2rem;
    color: white;
    text-align: center;
    margin-right: 1rem;
    margin-bottom: auto;
    line-height: 1rem;
    font-size: 0.8rem;
    @media screen and (max-width: 768px) {
      margin-right: 0.5rem;
      font-size: 0.8rem;
    }
  }
  .attrPercent {
    line-height: 1rem;
    border: 1.5px #3c3c3c solid;
    border-radius: 2rem;
    font-size: 0.8rem;
    // color: white;
    text-align: center;
    margin-bottom: auto;
    @media screen and (max-width: 768px) {
      font-size: 0.8rem;
    }
  }
}

.closeButton {
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 100;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  svg {
    height: 100%;
    width: 100%;
  }
}
.viewButtons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0rem;
  font-size: 0.9rem;
  a,
  a:hover,
  a:visited {
    color: #3c3c3c;
    cursor: pointer;
    display: block;
    width: 12rem;
    border: 1.5px #3c3c3c solid;
    text-decoration: none;
    border-radius: 2rem;
    padding: 0.2rem 1rem;
    margin: 0.5rem;
    svg {
      path {
        stroke: #3c3c3c;
      }
    }
  }
}

/* Paging */
.galleryPageNav {
  margin-top: auto;
  .active {
    background-color: #7c8481;
    color: white;
  }
  button:hover {
    opacity: 0.8;
  }
  button {
    border: #7c8481 1px solid;
    color: #7c8481;
    background-color: transparent;
    border-radius: 3em;
    margin: 0.25rem;
    height: 2rem;
    width: 2rem;
  }
}
